import style from "../css/Modal.module.css";
import { useEffect, useRef, useState } from "react";
import exit from "../img/component/modal_exit.png";

export default function ArtistModal({ setShowArtist, showArtist, artistData, isEn }) {
    const scrollRef = useRef();
    const modalRef = useRef();
    const divRef = useRef([]);
    const itemRef = useRef([]);
    const [isDrag, setIsDrag] = useState(false);
    const [startX, setStartX] = useState();
    const [showImg, setShowImg] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const onDragStart = (e) => {
        e.preventDefault();
        setIsDrag(true);
        setStartX(e.pageX + scrollRef.current.scrollLeft);
    };

    const onDragEnd = () => {
        setIsDrag(false);
    };

    const onDragMove = (e) => {
        if (isDrag) {
        scrollRef.current.scrollLeft = startX - e.pageX;
        }
    };

    const throttle = (func, ms) => {
        let throttled = false;
        return (...args) => {
            if (!throttled) {
            throttled = true;
            setTimeout(() => {
                func(...args);
                throttled = false;
            }, ms);
            }
        };
    };
    const onThrottleDragMove = throttle(onDragMove, 1);

    function exitModal() {
        setShowArtist(false)
    }

    function selectedImg(idx) {
        setShowImg(idx);
        scrollRef.current.scrollTo({ left: (screenWidth < 1300 ? 120 : 360) * idx, behavior: 'smooth' });
    }
    useEffect(() => {
        document.body.style = `overflow: hidden`;
        return () => document.body.style = `overflow: auto`
    }, [])

    useEffect(() => {
        window.addEventListener('resize', function() {
            setScreenWidth(window.innerWidth);
        });
        return(() => {
            window.removeEventListener('resize', function() {
                setScreenWidth(window.innerWidth);
            });
        })
    }, [])

    useEffect(() => {

        itemRef.current.map((item, idx) => {
            if(item !== null) {
                divRef.current[idx].style = item.offsetLeft > 400 ? "grid-row-end: span " + ((screenWidth < 1300 ? 80 : 128)+item.offsetHeight) + "; margin-left: 50px" : "grid-row-end: span " + ((screenWidth < 1300 ? 80 : 128)+item.offsetHeight) + ";"
            }
        })
    }, [artistData, window.innerWidth])

    return(
        <div ref={ modalRef } className={ showArtist ? `${style.wrap} ${style.open}` : `${style.wrap} ${style.close}`}>
            <div className={ style.containerTop }>
                <div className={ style.imgContainer }>
                    <img src={ artistData.imgUrl[showImg].imgUrl } alt="artist"></img>
                </div>
                <div className={ style.infoContainer }>
                    <span className={ isEn ? `${style.infoEname} ${style.hide}` : `${style.infoEname}` }>{ artistData.enName }</span>
                    <span className={ style.infoKname }>{ isEn ? artistData.enName : artistData.name  }</span>
                    <span className={ style.infoBirth }>{ isEn ? artistData.enBirth : artistData.birth }</span>
                </div>
                <hr className={ style.infoHr }/>
                <img src={ exit } alt="exit" className={ style.exit } onClick={ exitModal }></img>
            </div>
            <div ref={ scrollRef } className={ style.containerMiddle } onMouseDown={onDragStart} onMouseMove={isDrag ? onThrottleDragMove : null} onMouseUp={onDragEnd} onMouseLeave={onDragEnd}>
                {
                    artistData.imgUrl.map((img) => (
                        <div key={ img.id } className={ showImg === img.id-1 ? `${style.imgItem} ${style.selected}` : `${style.imgItem}` } onClick={ () => selectedImg(img.id - 1) }>
                            <img src={ img.imgUrl } alt="artist"></img>
                        </div>
                    ))
                }
            </div>
            <div className={ style.careerContainer }>
                {
                    (artistData.career).map((career, idx) => (
                        (career.content !== null && career.content !== "") &&
                        <div ref={ el => (divRef.current[idx] = el) } key={ idx } className={ style.careerItem }>
                            <div className={ style.careerTheme }>
                                <span>{ isEn ? career.enName : career.enName }</span>
                            </div>
                            <div ref={ el => (itemRef.current[idx] = el) } className={ style.careerContent }>
                                {
                                    isEn ?
                                        career.enContent.split(', ').map((content, idx) => (
                                            <span key={ idx }>{ content }</span>
                                        ))
                                    :
                                        career.content.split(', ').map((content, idx) => (
                                            <span key={ idx }>{ content }</span>
                                        ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}