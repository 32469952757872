import { useRef, useState, useEffect } from "react";
import axios from "axios";
import style from "../css/AdminModal.module.css";
import exit from "../img/component/admin_exit.svg";

export default function AdminProduction({ setShowAdmin, field, setRender }) {
    const [getData, setGetData] = useState([]);
    const [dataNum, setDataNum] = useState(null);
    const [selectItem, setSelectItem] = useState();
    const itemRef = useRef([]);
    const inputRef = useRef();
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const columnK = {
        album: "앨범",
        drama: "드라마",
        concert: "콘서트",
        movie: "영화"
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/api/data/production')
            .then((Response) => {
                if(Response.data.data.length !== 0) {
                    setGetData(Response.data.data[field]);
                    if(Response.data.data[field].length !== 0) {
                        setDataNum(0);
                        setSelectItem(Response.data.data[field][0].id);
                        setImageSrc(Response.data.data[field][0].imgUrl)
                    }
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
    }, [])

    function closeAdmin() {
        setRender(new Date());
        setShowAdmin(false);
    }

    function deleteItem(e, idx) {
        e.stopPropagation();
        if(selectItem === "") {
            if(window.confirm("앨범 추가를 취소하시겠습니까?")) {
                setGetData(getData.filter(data => data.id !== ""))
                setSelectItem(getData.filter(data => data.id !== "")[0].id);
                itemRef.current[0].value = getData.filter(data => data.id !== "")[0]['theme'];
                itemRef.current[1].value = getData.filter(data => data.id !== "")[0]['enTheme'];
                itemRef.current[2].value = new Date(getData.filter(data => data.id !== "")[0]['year']).getFullYear().toString();
                itemRef.current[3].value = getData.filter(data => data.id !== "")[0]['director'];
                itemRef.current[4].value = getData.filter(data => data.id !== "")[0]['screenplay'];
                itemRef.current[5].value = getData.filter(data => data.id !== "")[0]['organization'];
                itemRef.current[6].value = getData.filter(data => data.id !== "")[0]['enDirector'];
                itemRef.current[7].value = getData.filter(data => data.id !== "")[0]['enScreenplay'];
                itemRef.current[8].value = getData.filter(data => data.id !== "")[0]['enOrganization'];
                itemRef.current[9].value = getData.filter(data => data.id !== "")[0]['actor'];
                itemRef.current[10].value = getData.filter(data => data.id !== "")[0]['enActor'];
                setImageSrc(getData.filter(data => data.id !== "")[0]['imgUrl']);
            }
            return
        }
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios.delete(process.env.REACT_APP_SERVER + '/api/admin/delete/production', { data: {id: idx, column: field, imgUrl: getData.filter(data => data.id ===idx)[0]['imgUrl']}, withCredentials: true, })
            .then((Response) => {
                if(Response.data.state === "success") {
                    const newData = getData.filter(data => data.id !== idx);
                    setGetData(newData);
                    if(newData.length !== 0) {
                        setDataNum(0);
                        setSelectItem(newData[0].id);
                        itemRef.current[0].value = newData[0]['theme'];
                        itemRef.current[1].value = newData[0]['enTheme'];
                        itemRef.current[2].value = new Date(newData[0]['year']).getFullYear().toString();
                        itemRef.current[3].value = getData.filter(data => data.id !== "")[0]['director'];
                        itemRef.current[4].value = getData.filter(data => data.id !== "")[0]['screenplay'];
                        itemRef.current[5].value = getData.filter(data => data.id !== "")[0]['organization'];
                        itemRef.current[6].value = getData.filter(data => data.id !== "")[0]['enDirector'];
                        itemRef.current[7].value = getData.filter(data => data.id !== "")[0]['enScreenplay'];
                        itemRef.current[8].value = getData.filter(data => data.id !== "")[0]['enOrganization'];
                        itemRef.current[9].value = getData.filter(data => data.id !== "")[0]['actor'];
                        itemRef.current[10].value = getData.filter(data => data.id !== "")[0]['enActor'];
                        setImageSrc(newData[0]['imgUrl']);
                    } else {
                        itemRef.current[0].value = "";
                        itemRef.current[1].value = "";
                        itemRef.current[2].value = "";
                        itemRef.current[3].value = "";
                        itemRef.current[4].value = "";
                        itemRef.current[5].value = "";
                        itemRef.current[6].value = "";
                        itemRef.current[7].value = "";
                        itemRef.current[8].value = "";
                        itemRef.current[9].value = "";
                        itemRef.current[10].value = "";
                        setImageSrc(null);
                    }
                    alert("삭제가 완료되었습니다.");
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
        }
    }

    function changeTarget(dataId, idx) {
        if(selectItem === "") {
            if(idx === 0 && selectItem === "") {
                return
            }
            if(window.confirm("변경된 정보를 저장하시겠습니까?")) {
                addItem()
                return
            } else {
                setGetData(getData.filter(data => data.id !== ""))
                setSelectItem(dataId);
                setDataNum(idx-1);
                itemRef.current[0].value = getData.filter(data => data.id !== "")[0]['theme'];
                itemRef.current[1].value = getData.filter(data => data.id !== "")[0]['enTheme'];
                itemRef.current[2].value = new Date(getData.filter(data => data.id !== "")[0]['year']).getFullYear().toString();
                itemRef.current[3].value = getData.filter(data => data.id !== "")[0]['director'];
                itemRef.current[4].value = getData.filter(data => data.id !== "")[0]['screenplay'];
                itemRef.current[5].value = getData.filter(data => data.id !== "")[0]['organization'];
                itemRef.current[6].value = getData.filter(data => data.id !== "")[0]['enDirector'];
                itemRef.current[7].value = getData.filter(data => data.id !== "")[0]['enScreenplay'];
                itemRef.current[8].value = getData.filter(data => data.id !== "")[0]['enOrganization'];
                itemRef.current[9].value = getData.filter(data => data.id !== "")[0]['actor'];
                itemRef.current[10].value = getData.filter(data => data.id !== "")[0]['enActor'];
                setImageSrc(getData.filter(data => data.id !== "")[0]['imgUrl']);
                return
            }
        }

        if( getData[dataNum].theme === itemRef.current[0].value &&
            getData[dataNum].enTheme === itemRef.current[1].value &&
            new Date(getData[dataNum].year).getFullYear().toString() === itemRef.current[2].value &&
            getData[dataNum].imgUrl === imageSrc) {
                setSelectItem(dataId);
                setDataNum(idx);
            } else {
                if(window.confirm("변경된 정보를 저장하시겠습니까?")) {
                    const newData = {
                        id: selectItem,
                        column: field,
                        theme: itemRef.current[0].value.replaceAll('\'', '\\\''),
                        enTheme : itemRef.current[1].value.replaceAll('\'', '\\\''),
                        year : itemRef.current[2].value + "-01-01",
                        director: itemRef.current[3].value,
                        screenplay: itemRef.current[4].value,
                        organization: itemRef.current[5].value,
                        enDirector: itemRef.current[6].value,
                        enScreenplay: itemRef.current[7].value,
                        enOrganization: itemRef.current[8].value,
                        actor: itemRef.current[9].value,
                        enActor: itemRef.current[10].value,
                        beforeImgUrl: getData[dataNum].imgUrl
                    }
                    const formData = new FormData();
                    formData.set('file', imageFile);
                    formData.set('data', JSON.stringify(newData));
                    axios({
                        method: "put",
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        url: process.env.REACT_APP_SERVER + "/api/admin/put/production",
                        data: formData,
                    })
                    .then((Response) => {
                        if(Response.data.state === "success") {
                            const newGetData = [...getData];
                            newGetData[dataNum] = {
                                id: newData.id,
                                theme: newData.theme,
                                enTheme: newData.enTheme,
                                year: new Date(newData.year).getFullYear().toString(),
                                director: newData.director,
                                screenplay: newData.screenplay,
                                organization: newData.organization,
                                enDirector: newData.enDirector,
                                enScreenplay: newData.enScreenplay,
                                enOrganization: newData.enOrganization,
                                actor: newData.actor,
                                enActor: newData.enActor,
                                imgUrl: Response.data.imgUrl
                            }
                            setGetData(newGetData)
                            alert("저장되었습니다.")
                        }
                    })
                }
                setSelectItem(dataId);
                setDataNum(idx);
            }
    }

    function createItem() {
        if(selectItem === "") {
            alert("현재 입력중인 데이터가 있습니다.");
            return
        }
        const addData = {
            id: "",
            theme: "",
            enTheme: "",
            year: "",
            director: "",
            screenplay: "",
            organization: "",
            enDirector: "",
            enScreenplay: "",
            enOrganization: "",
            actor: "",
            enActor: "",
            imgUrl: ""
        }
        setGetData([addData, ...getData]);
        setDataNum(0);
        setSelectItem("");
        setImageSrc(null);
        itemRef.current[0].value = ""
        itemRef.current[1].value = ""
        itemRef.current[2].value = ""
        itemRef.current[3].value = ""
        itemRef.current[4].value = ""
        itemRef.current[5].value = ""
        itemRef.current[6].value = ""
        itemRef.current[7].value = ""
        itemRef.current[8].value = ""
        itemRef.current[9].value = ""
        itemRef.current[10].value = ""
        inputRef.current.value = null;
    }

    function addItem() {
        if(itemRef.current[0].value !== "" && itemRef.current[1].value !== "" && imageSrc !== "") {
            if(selectItem !== "") {
                if(window.confirm("변경된 정보를 저장하시겠습니까?")) {
                    const newData = {
                        id: selectItem,
                        column: field,
                        theme: itemRef.current[0].value.replaceAll('\'', '\\\''),
                        enTheme : itemRef.current[1].value.replaceAll('\'', '\\\''),
                        year : itemRef.current[2].value + "-01-01",
                        director: itemRef.current[3].value,
                        screenplay: itemRef.current[4].value,
                        organization: itemRef.current[5].value,
                        enDirector: itemRef.current[6].value,
                        enScreenplay: itemRef.current[7].value,
                        enOrganization: itemRef.current[8].value,
                        actor: itemRef.current[9].value,
                        enActor: itemRef.current[10].value,
                        beforeImgUrl: getData[dataNum].imgUrl
                    }
                    const formData = new FormData();
                    formData.set('file', imageFile);
                    formData.set('data', JSON.stringify(newData));
                    axios({
                        method: "put",
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        url: process.env.REACT_APP_SERVER + "/api/admin/put/production",
                        data: formData,
                    })
                    .then((Response) => {
                        if(Response.data.state === "success") {
                            const newGetData = [...getData];
                            newGetData[dataNum] = {
                                id: newData.id,
                                theme: newData.theme,
                                enTheme: newData.enTheme,
                                year: new Date(newData.year).getFullYear().toString(),
                                director: newData.director,
                                screenplay: newData.screenplay,
                                organization: newData.organization,
                                enDirector: newData.enDirector,
                                enScreenplay: newData.enScreenplay,
                                enOrganization: newData.enOrganization,
                                actor: newData.actor,
                                enActor: newData.enActor,
                                imgUrl: Response.data.imgUrl
                            }
                            setGetData(newGetData)
                            setImageSrc(Response.data.imgUrl);
                            alert("저장되었습니다.")
                        }
                    })
                }
            } else {
                const data = JSON.stringify({
                    name: field,
                    theme: itemRef.current[0].value.replaceAll('\'', '\\\''),
                    enTheme : itemRef.current[1].value.replaceAll('\'', '\\\''),
                    year: itemRef.current[2].value + "-01-01",
                    director: itemRef.current[3].value.replaceAll('\'', '\\\''),
                    screenplay: itemRef.current[4].value.replaceAll('\'', '\\\''),
                    organization: itemRef.current[5].value.replaceAll('\'', '\\\''),
                    enDirector: itemRef.current[6].value.replaceAll('\'', '\\\''),
                    enScreenplay: itemRef.current[7].value.replaceAll('\'', '\\\''),
                    enOrganization: itemRef.current[8].value.replaceAll('\'', '\\\''),
                    actor: itemRef.current[9].value.replaceAll('\'', '\\\''),
                    enActor: itemRef.current[10].value.replaceAll('\'', '\\\'')
                })
                const formData = new FormData();
                formData.set('file', imageFile);
                formData.set('data', data);
                axios({
                    method: "post",
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    url: process.env.REACT_APP_SERVER + "/api/admin/add/production",
                    data: formData,
                })
                .then((Response) => {
                    if(Response.data.state === "success") {
                        axios.get(process.env.REACT_APP_SERVER + '/api/data/production')
                        .then((Response2) => {
                            setGetData(Response2.data.data[field]);
                            if(Response2.data.data[field].length !== 0) {
                                setSelectItem(Response2.data.data[field][0].id);
                                setImageSrc(Response2.data.data[field][0].imgUrl);
                                setDataNum(0);
                                itemRef.current[0].value = Response2.data.data[field][0]['theme'];
                                itemRef.current[1].value = Response2.data.data[field][0]['enTheme'];
                                itemRef.current[2].value = Response2.data.data[field][0]['year'] === "" ? "" : new Date(Response2.data.data[field][0]['year']).getFullYear().toString();
                                itemRef.current[3].value = Response2.data.data[field][0]['director'];
                                itemRef.current[4].value = Response2.data.data[field][0]['screenplay'];
                                itemRef.current[5].value = Response2.data.data[field][0]['organization'];
                                itemRef.current[6].value = Response2.data.data[field][0]['enDirector'];
                                itemRef.current[7].value = Response2.data.data[field][0]['enScreenplay'];
                                itemRef.current[8].value = Response2.data.data[field][0]['enOrganization'];
                                itemRef.current[9].value = Response2.data.data[field][0]['actor'];
                                itemRef.current[10].value = Response2.data.data[field][0]['enActor'];
                            }
                            setImageFile(null);
                            inputRef.current.value = null;
                            alert("추가가 완료되었습니다.");
                        })
                        .catch((Error) => {
                            console.log(Error)
                        })
                    } else {
                        alert(Response.data.message);
                    }
                })
                .catch((Error) => {
                    console.log(Error);
                })
                return
            }
        } else {
            alert("필수항목을 입력해주시기 바랍니다.")
            return
        }
    }

    function onUploadImage(e) {
        if (e.target.value.length === 0) {
            return;
        } else if(e.target.files[0].size > e.target.size) {
            alert("파일 용량이 10MB를 초과합니다.");
            return
        } else {
            let reader = new FileReader();

            if(e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                setImageFile(e.target.files[0]);
            }

            reader.onloadend = function() {
                setImageSrc(reader.result);
            }
        }
    };

    function previewImage() {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.click();
    }

    useEffect(() => {
        if(getData.length !== 0) {
            itemRef.current[0].value = getData[dataNum]['theme'];
            itemRef.current[1].value = getData[dataNum]['enTheme'];
            itemRef.current[2].value = getData[dataNum]['year'] === "" ? "" : new Date(getData[dataNum]['year']).getFullYear().toString();
            itemRef.current[3].value = getData[dataNum]['director'];
            itemRef.current[4].value = getData[dataNum]['screenplay'];
            itemRef.current[5].value = getData[dataNum]['organization'];
            itemRef.current[6].value = getData[dataNum]['enDirector'];
            itemRef.current[7].value = getData[dataNum]['enScreenplay'];
            itemRef.current[8].value = getData[dataNum]['enOrganization'];
            itemRef.current[9].value = getData[dataNum]['actor'];
            itemRef.current[10].value = getData[dataNum]['enActor'];
            setImageSrc(getData[dataNum]['imgUrl']);
        }
    }, [dataNum])

    return (
     <div className={ `${style.container} ${style.production}` }>
            <div className={ style.topContainer }>
                <span>{ columnK[field] + " 관리" }</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ style.middleContainer }>
                <div className={ style.middleProductionWrap }>
                    <div className={ style.middleProduction }>
                        <div className={ style.themeContainer }>
                            <span className={ style.middleTheme }>{ columnK[field] + " 목록" }</span>
                            <hr className={ style.middleLine }></hr>
                        </div>
                        <div className={ style.productionContent }>
                            <div className={ style.productionListContainer }>
                                {
                                    getData.map((data, idx) => (
                                        <div key={ data.id } className={ dataNum === idx ? `${style.productionListItem} ${style.selected}` : style.productionListItem } onClick={ () => changeTarget(data.id, idx) }>
                                            <div className={ style.productionListItemNum }>
                                                <span>{idx+1}</span>
                                            </div>
                                            <div className={ style.productionListItemName }>
                                                <span>{data.theme}</span>
                                            </div>
                                            <div className={ style.deleteItemBtn } onClick={ (e) => deleteItem(e, data.id) }>
                                                <div>
                                                    <hr></hr>
                                                    <hr></hr>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className={ `${style.actionButton2} ${style.fncProductionBtn}` } onClick={ createItem }>
                                { columnK[field] + " 추가"}
                            </div>
                        </div>
                    </div>
                    <div className={ style.middleProduction }>
                        <div className={ style.themeContainer }>
                            <span className={ style.middleTheme }>{ columnK[field] + " 등록/수정" }</span>
                            <hr className={ style.middleLine }></hr>
                        </div>
                        <div className={ style.productionContent2 }>
                            <div className={ style.productionContentInfo }>
                                <div className={ style.contentInfoContainer }>
                                    <span className={ style.productionContentTheme } >{ columnK[field] + " 정보"}</span>
                                </div>
                                <div className={ style.infoItemContainer }>
                                    <div className={ style.infoItem }>
                                        <span>제목<span className={ style.required }>*필수</span></span>
                                        <input type="text" ref={ el => (itemRef.current[0] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>감독</span>
                                        <input type="text" ref={ el => (itemRef.current[3] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>극본</span>
                                        <input type="text" ref={ el => (itemRef.current[4] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>편성</span>
                                        <input type="text" ref={ el => (itemRef.current[5] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>편성연도<span className={ style.required }>*필수</span></span>
                                        <input type="text" ref={ el => (itemRef.current[2] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>배우</span>
                                        <input type="text" ref={ el => (itemRef.current[9] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>Title<span className={ style.required }>*필수</span></span>
                                        <input type="text" ref={ el => (itemRef.current[1] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>Director</span>
                                        <input type="text" ref={ el => (itemRef.current[6] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>Screenplay</span>
                                        <input type="text" ref={ el => (itemRef.current[7] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>Organization</span>
                                        <input type="text" ref={ el => (itemRef.current[8] = el) }></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>Actor</span>
                                        <input type="text" ref={ el => (itemRef.current[10] = el) }></input>
                                    </div>
                                </div>
                            </div>
                            <div className= { style.productionContentImage }>
                                <div className={ style.contentImageContainer }>
                                    <span className={ style.productionContentTheme } >{ columnK[field] + " 이미지"}<span className={ style.required }>*필수</span></span>
                                    <span className={ style.productionContentMent }>- 10mb 미만의 이미지만 업로드할 수 있습니다.</span>
                                </div>
                                <div className={ style.infoImage }>
                                    <div className={ field === "album" ? `${style.productionImage} ${style.isalbum}` : style.productionImage }>
                                        {
                                            imageSrc && <img src={ imageSrc } alt="previewImage" onClick={ previewImage }></img>
                                        }
                                        <div className={ style.productionPlus } onClick={ previewImage }>
                                            <hr className={ style.plusShape }></hr>
                                            <hr className={ style.plusShape }></hr>
                                        </div>
                                        <span className={ style.plusMent }>600*400px 이상의 이미지 업로드를 권장합니다. (다른 비율로 업로드 시 이미지 비율 훼손)</span>
                                        <input type="file" accept="image/*" ref={ inputRef } onChange={ onUploadImage } size="10485760"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={ addItem }>
                    <span>저장하기</span>
                </div>
            </div>
        </div>
    )
}