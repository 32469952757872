import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

const con = document.getElementById('root')
const root = ReactDOM.createRoot(con);

if(con.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    con,
    <HelmetProvider>
      <App />
    </HelmetProvider>
  )
} else {
  root.render(
    <HelmetProvider>
      <App />
    </HelmetProvider>
  );
}

reportWebVitals();
