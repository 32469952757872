import { useState, useEffect } from "react";
import axios from "axios";
import style from "../css/Pages2.module.css";
import adminStyle from "../css/AdminModal.module.css";
import ArtistItem from "../PageComponents/ArtistItem";
import Portal from "../Components/Portal";
import ArtistModal from "../PageComponents/ArtistModal";
import { Transition } from "react-transition-group";
import NavigationBox from "../PageComponents/NavigationBox";
import AdminModal from "../PageComponents/AdminModal";
import SEOMeta from "../PageComponents/SEOMeta";

export default function Artist({ isEn, setSeoData, isAdmin, showAdmin, setShowAdmin, field, setField, render, setRender }) {
    const [getData, setData] = useState([]);
    const [artistData, setArtistData] = useState();
    const [showArtist, setShowArtist] = useState(false);
    const [artistIdx, setArtistIdx] = useState();

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER +'/api/data/artist')
            .then((Response) => {
                setData(Response.data.data);
                if(Response.data.data.length !== 0) {
                    setArtistIdx(Response.data.data[0].id)
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
    }, [render])

    useEffect(() => {
        if(artistIdx !== undefined) {
            axios.get(process.env.REACT_APP_SERVER + '/api/data/artist/' + artistIdx)
                .then((Response) => {
                    setArtistData(Response.data.data[0]);
                })
                .catch((Error) => {
                    console.log(Error)
                })
        }
    }, [artistIdx])

    return(
        <div className={ style.wrap }>
            <SEOMeta title="Artist ㅣ 빌리언스" description="빌리언스 아티스트 프로필 제공" url="https://bladeent.com/artist"></SEOMeta>
            <div className={ style.container }>
                <NavigationBox page="Artist" />
                <div className={ style.artistContainer }>
                    {
                        Array.isArray(getData) && getData.length !== 0 ?
                        <ArtistItem data={ getData } isEn={ isEn } setShowArtist={ setShowArtist } setArtistIdx={ setArtistIdx } />
                        :
                        <div className={style.artistNodataContainer}>
                            <span className={ style.productionNoData }>Comming Soon!</span>
                        </div>
                    }
                </div>
                {
                     isAdmin &&
                     <div className={ adminStyle.introArtistContainer }>
                        <div className={ adminStyle.actionButton } onClick={ () => { setShowAdmin(true); setField("artist") } }>
                            <span>프로필 관리</span>
                        </div>
                    </div>
                }
            </div>
            <Portal>
                <Transition unmountOnExit in={ showArtist } timeout={400}>
                    { <ArtistModal setShowArtist={ setShowArtist } showArtist={ showArtist } artistData={ artistData } isEn={ isEn }/> }
                </Transition>
            </Portal>
            {
                (field === "artist") &&
                <Portal>
                    <Transition unmountOnExit in={ showAdmin } timeout={0}>                      
                        <AdminModal showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setRender={ setRender } />
                    </Transition>
                </Portal>
            }
        </div>
    )
}