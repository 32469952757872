import style from "../css/AdminModal.module.css";
import { useEffect } from "react";
import AdminLogin from "../AdminComponents/AdminLogin";
import AdminMain from "../AdminComponents/AdminMain";
import AdminProduction from "../AdminComponents/AdminProduction";
import AdminProduction2 from "../AdminComponents/AdminProduction2";
import AdminProduction3 from "../AdminComponents/AdminProduction3";
import AdminProduction4 from "../AdminComponents/AdminProduction4";
import AdminNews from "../AdminComponents/AdminNews";
import AdminArtist from "../AdminComponents/AdminArtist";

export default function AdminModal({ isAdmin, setIsAdmin, showAdmin, setShowAdmin, field, originData, setRender }) {
    const components = {
        login: AdminLogin,
        main: AdminMain,
        album: AdminProduction,
        drama: AdminProduction2,
        concert: AdminProduction,
        movie: AdminProduction3,
        producer: AdminProduction4,
        news: AdminNews,
        notice: AdminNews,
        artist: AdminArtist,
        musician: AdminArtist
    }
    const ActiveComponent = components[field];

    useEffect(() => {
        document.body.style = `overflow: hidden`;
        return () => document.body.style = `overflow: auto`
    }, [])

    return(
        <div className={ style.wrap }>
            <ActiveComponent setIsAdmin={ setIsAdmin } setShowAdmin={ setShowAdmin } field={ field } originData={ originData } setRender={ setRender }/>
        </div>
    )
}