import style from "../css/Pages.module.css";

export default function ProductionItem({getData, isEn, column }) {
    if(column == "Album" || column == "Concert"){
        return(
            Array.isArray(getData) && getData.map((data, idx) => (
                <div key={data.id} className={style.productionItem} style={{ animationDelay: ((idx+1) * 0.1 + 1.2) +"s" }}>
                    <div className={style.productionItemImg}>
                        <img src={data.imgUrl} alt={isEn ? data.enTheme : data.theme}></img>
                    </div>
                    <div className={style.productionItemInfoContainer}>
                        <div className={style.productionItemInfoWrap}>
                            <div className={style.productionItemInfo}>
                                <span className={style.productionItemTheme}>{isEn ? data.enTheme : data.theme}</span>
                                <hr></hr>
                                    <div className={style.prodictionItemList}>
                                        
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        )
    }else{
        console.log(column)
        return(
            Array.isArray(getData) && getData.map((data, idx) => (
                <div key={data.id} className={style.productionItem} style={{ animationDelay: ((idx+1) * 0.1 + 1.2) +"s" }}>
                    <div className={style.productionItemImg}>
                        <img src={data.imgUrl} alt={isEn ? data.enTheme : data.theme}></img>
                    </div>
                    <div className={style.productionItemInfoContainer}>
                        <div className={style.productionItemInfoWrap}>
                            <div className={style.productionItemInfo}>
                                <span className={style.productionItemTheme}>{isEn ? data.enTheme : data.theme}</span>
                                <hr></hr>
                                {
                                    column === "Drama" ?
                                    <div className={style.prodictionItemList}>
                                        {
                                            data.director !== "" && data.director !== null &&
                                            <span className={style.listTheme}>{isEn ? "Director" : "감독"}</span>
                                        }
                                        {
                                            data.director !== "" && data.director !== null &&
                                            <span className={style.listContent}>{isEn ? data.enDirector : data.director}</span>
                                        }
                                        {
                                            data.screenplay !== "" && data.screenplay !== null &&
                                            <span className={style.listTheme}>{isEn ? "Screenplay" : "극본"}</span>
                                        }
                                        {
                                            data.screenplay !== "" && data.screenplay !== null &&
                                            <span className={style.listContent}>{isEn ? data.enScreenplay : data.screenplay}</span>
                                        }
                                        {
                                            data.organization !== "" && data.organization !== null &&
                                            <span className={style.listTheme}>{isEn ? "Organization" : "편성정보"}</span>
                                        }
                                        {
                                            data.organization !== "" && data.organization !== null &&
                                            <span className={style.listContent}>{isEn ? data.enOrganization : data.organization}{" " + new Date(data.year).getFullYear()}</span>
                                        }
                                        {
                                            data.actor !== "" && data.actor !== null &&
                                            <span className={style.listTheme}>{isEn ? "Main Actor" : "주연배우"}</span>
                                        }
                                        {
                                            data.actor !== "" && data.actor !== null &&
                                            <span className={style.listContent}>{isEn ? data.enActor : data.actor}</span>
                                        }
                                    </div>
                                    :
                                    <div className={style.prodictionItemList}>
                                        {
                                            data.director !== "" && data.director !== null &&
                                            <span className={style.listTheme}>{isEn ? "Director" : "감독"}</span>
                                        }
                                        {
                                            data.director !== "" && data.director !== null &&
                                            <span className={style.listContent}>{isEn ? data.enDirector : data.director}</span>
                                        }
                                        {
                                            data.screenplay !== "" && data.screenplay !== null &&
                                            <span className={style.listTheme}>{isEn ? "Screenplay" : "각본"}</span>
                                        }
                                        {
                                            data.screenplay !== "" && data.screenplay !== null &&
                                            <span className={style.listContent}>{isEn ? data.enScreenplay : data.screenplay}</span>
                                        }
                                        {
                                            data.actor !== "" && data.actor !== null &&
                                            <span className={style.listTheme}>{isEn ? "Main Actor" : "주연배우"}</span>
                                        }
                                        {
                                            data.actor !== "" && data.actor !== null &&
                                            <span className={style.listContent}>{isEn ? data.enActor : data.actor}</span>
                                        }
                                        {
                                            data.opendate !== "" && data.opendate !== null &&
                                            <span className={style.listTheme}>{isEn ? "Opening Date" : "개봉일"}</span>
                                        }
                                        {
                                            data.opendate !== "" && data.opendate !== null &&
                                            <span className={style.listContent}>{isEn ? data.enOpendate : data.opendate}</span>
                                        }
                                        {
                                            data.runningtime !== "" && data.runningtime !== null &&
                                            <span className={style.listTheme}>{isEn ? "Running Time" : "러닝타임"}</span>
                                        }
                                        {
                                            data.runningtime !== "" && data.runningtime !== null &&
                                            <span className={style.listContent}>{data.runningtime}{isEn ? " minutes" : "분"}</span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ))
        )
    }
    
}