import style from "../css/Pages.module.css";
import adminStyle from "../css/AdminModal.module.css";
import listDown from "../img/component/list_down.png"
import listUp from "../img/component/list_up.png"
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import ProductionItem from "../PageComponents/ProductionItem";
import NavigationBox from "../PageComponents/NavigationBox";
import Portal from "../Components/Portal";
import { Transition } from "react-transition-group";
import AdminModal from "../PageComponents/AdminModal";
import SEOMeta from "../PageComponents/SEOMeta";

export default function Production({ isEn, setSeoData, isAdmin, showAdmin, setShowAdmin, field, setField, render, setRender }) {
    const [getParams] = useSearchParams('');
    const column = getParams.get('column');
    const [getData, setData] = useState([]);
    const columnK = {
        Album: "앨범",
        Drama: "드라마",
        Concert: "콘서트",
        Movie: "영화",
        Producer: "프로듀서",
    }

    function openList(e) {
        var nodeHeight = window.innerWidth > 1300 ? "248px" : "136px"
        if(e.target.src === listDown) {
            e.target.src=listUp
            e.target.parentNode.style.height="auto"
        } else {
            e.target.src=listDown
            e.target.parentNode.style.height= nodeHeight
        }
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/api/data/production')
            .then((Response) => {
                setData(Response.data.data);
            })
            .catch((Error) => {
                console.log(Error)
            })
    }, [render])
    
    return(
        <div key={ column } className={ style.wrap }>
            <SEOMeta title="Production ㅣ 빌리언스" description="빌리언스는 사람과 사람 그리고 마음과 마음을 잇는 이야기를 제작합니다." url="https://bladeent.com/production"></SEOMeta>
            <div className={ style.container }>
                <NavigationBox page={column} />
                <div className={ style.commentContainer }>
                    <div className={ style.commentTheme }>
                        <span>K Content Is Everywhere</span>
                    </div>
                    <div className={ `${style.commentContent} ${style.productionComment}` }>
                        <span>{ isEn ? "BILLIONS creates stories that connect people to people and hearts to hearts. We deliver messages and create resonance through content." : "BILLIONS는 사람과 사람 그리고 마음과 마음을 잇는 이야기를 제작합니다. 콘텐츠를 통하여 메시지를 전달하고 울림을 만들어갑니다." }</span>
                    </div>
                </div>
                {
                    column !== "Producer" &&
                    column.split('/').map((column, idx) => (
                        <div key={ column } className={ style.productionContainer }>
                            <div className={ style.productionTheme }>
                                <span>{ column }</span>
                                <hr></hr>
                            </div>
                            {
                                Array.isArray(getData[column.toLowerCase()]) && getData[column.toLowerCase()].length !== 0 ?
                                <div className={ style.productionItemContainer }>
                                    <ProductionItem getData={ getData[column.toLowerCase()] } isEn={ isEn } column={ column } />
                                </div>
                                :
                                <div className={ style.productionNodataContainer }>
                                    <span className={ style.productionNoData }>Comming Soon!</span>
                                </div>
                            }
                            {
                                isAdmin && <div className={ `${adminStyle.actionButton} ${adminStyle.introProductionBtn}` } onClick={ () => { setShowAdmin(true); setField(column.toLowerCase()) } }>{columnK[column] + " 관리"}</div>
                            }
                        </div>
                    ))
                }
                {
                    column === "Producer" &&
                    <div className={ style.productionContainer }>
                        <div className={ style.productionTheme }>
                            <span>Producer</span>
                            <hr></hr>
                        </div>
                        {
                            Array.isArray(getData['producer']) && getData['producer'].length !== 0 ?
                            <div className={ style.productionItemContainer }>
                                {
                                    getData['producer'].map((item) => (
                                        <div key={ item.id } className={ style.producerItem }>
                                            <div className={ style.producerItemInfo }>
                                                <img src={ item.imgUrl } alt="producerImage"></img>
                                                <div className={ style.producerItemName }>
                                                    <span>{ isEn ? item.enDepartment : item.department }</span>
                                                    <span>{ isEn ? item.enName : item.name }</span>
                                                </div>
                                            </div>
                                            <div className={ style.producerItemWork }>
                                                <span>Work</span>
                                                <div className={ style.workItem }>
                                                    {
                                                        isEn ?
                                                        item.enWork.split(',').map((item) =>(
                                                            <span key={ item + Math.random() }>{item.trim()}</span>
                                                        ))
                                                        :
                                                        item.work.split(',').map((item) =>(
                                                            <span key={ item + Math.random() }>{item.trim()}</span>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <img className={ style.listArrow } src={ listDown } alt="listImage" onClick={ openList }></img>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div className={ style.productionNodataContainer }>
                                <span className={ style.productionNoData }>Comming Soon!</span>
                            </div>
                        }
                        {
                            isAdmin && <div className={ `${adminStyle.actionButton} ${adminStyle.introProductionBtn}` } onClick={ () => { setShowAdmin(true); setField(column.toLowerCase()) } }>{columnK[column] + " 관리"}</div>
                        }
                    </div>
                }
            </div>
            {
                (field === "drama" || field === "album" || field === "concert" || field === "movie" || field === "producer") &&
                <Portal>
                    <Transition unmountOnExit in={ showAdmin } timeout={0}>                      
                        <AdminModal showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setRender={ setRender } />
                    </Transition>
                </Portal>
            }
        </div>
    )
}