import style from "../css/Pages2.module.css";
import adminStyle from "../css/AdminModal.module.css";
import { createRoutesFromElements, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import NewsItem from "../PageComponents/NewsItem";
import NoticeItem from "../PageComponents/NoticeItem";
// import Pagination from "../Components/Pagination";
import NavigationBox from "../PageComponents/NavigationBox";
import Portal from "../Components/Portal";
import { Transition } from "react-transition-group";
import AdminModal from "../PageComponents/AdminModal";
import SEOMeta from "../PageComponents/SEOMeta";

export default function News({ isEn, setSeoData, isAdmin, showAdmin, setShowAdmin, field, setField, render, setRender }) {
    const [getParams, setgetParams] = useSearchParams('');
    const column = getParams.get('column');
    // const pagenum = getParams.get('pagenum');
    // const [getColumn, setColumn] = useState('News');
    const [getData, setData] = useState({news: [], notice: []});
    const components = {
        news: NewsItem,
        notice: NoticeItem
    }
    const ActiveComponent = components[column.toLowerCase()];
    const columnK = {
        News: "뉴스",
        Notice: "공지사항",
    }
    // const [currentPage, setCurrentPage] = useState(Number(pagenum));
    // const [postsPerPage, setPostsPerPage] = useState(8);

    // const indexOfLast = currentPage * postsPerPage;
    // const indexOfFirst = indexOfLast - postsPerPage;
    // const currentPosts = function(posts) {
    //     let currentPosts = 0;
    //     currentPosts = posts.slice(indexOfFirst, indexOfLast);
    //     return currentPosts;
    // };

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/api/data/' + column.toLowerCase())
            .then((Response) => {
                setData({...getData, [column.toLowerCase()]: Response.data.data});
            })
            .catch((Error) => {
                console.log(Error)
            })
        // if(column !== getColumn) {
        //     setCurrentPage(1);
        //     setColumn(column);
        // }
    }, [column, render])

    // useEffect(() => {
    //     getParams.set('pagenum', currentPage)
    //     setgetParams(getParams)
    // }, [currentPage])

    return(
        <div key={ column + render } className={ style.wrap }>
            <SEOMeta title="News ㅣ 빌리언스" description="빌리언스의 각종 소식과 뉴스를 만나보세요." url="https://bladeent.com/news"></SEOMeta>
            <div className={ style.container }>
                <NavigationBox page={column} />
            </div>
            <div className={ `${style.contentContainer} ${style[column.toLowerCase()]}` }>
                {
                    // currentPosts(getData[column.toLowerCase()]).map((data, idx) => (
                    //     <ActiveComponent key={ data.id } isEn={ isEn } data={ data } idx={ idx } />
                    // ))
                    getData[column.toLowerCase()].map((data, idx) => (
                        <ActiveComponent key={ data.id } isEn={ isEn } data={ data } idx={ idx } />
                    ))
                }
            </div>
            {
                isAdmin && <div className={ `${adminStyle.actionButton} ${adminStyle.introNewsBtn}` } onClick={ () => { setShowAdmin(true); setField(column.toLowerCase()) } }>{columnK[column] + " 작성"}</div>
            }
            {
                (field === "news" || field === "notice") &&
                <Portal>
                    <Transition unmountOnExit in={ showAdmin } timeout={0}>                      
                        <AdminModal showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setRender={ setRender } />
                    </Transition>
                </Portal>
            }
        </div>
    )
}