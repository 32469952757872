import { useRef, useState, useEffect } from "react";
import axios from "axios";
import style from "../css/AdminModal.module.css";
import exit from "../img/component/admin_exit.svg";

export default function AdminArtist({ setShowAdmin, field, originData, setRender }) {
    const columnK = {
        artist: ["아티스트", "배우"],
        musician: ["뮤지션", "뮤지션"],
    }
    //단순 이미지 네이밍변수
    const imgArr = [];
    //첨부될 파일변수
    var imgFileArr = [];
    const careerArr = ["수상", "Award", "영화", "Film", "드라마", "Drama", "CF", "CF", "연극", "Theater", "공연", "Show", "뮤지컬", "Musical", "방송", "Broadcast", "뮤직비디오", "Music Video", "패션쇼", "Fashion Show", "예능", "Entertainment", "웹드라마", "Web Drama"];
    for(var i=0; i<20; i++) {
        imgArr.push({id: i, tag: i === 0 ? "Main" : i+1})
    }
    const [getData, setGetData] = useState([]);         //전체데이터 저장변수
    const [dataNum, setDataNum] = useState(null);       //선택된 index
    const [selectIdx, setSelectIdx] = useState(null);   //선택된 고유id
    const [selectItem, setSelectItem] = useState();     //선택된 아이템정보
    const itemRef = useRef([]);                         //텍스트 3개 ref
    const careerRef = useRef([]);                       //경력 14개 ref
    const imgRef = useRef([])                           //이미지 20개 ref
    const inputRef = useRef([]);                        //이미지파일첨부 20개 ref

    function closeAdmin() {
        setRender(new Date())
        setShowAdmin(false);
    }

    function deleteItem(e, id) {
        e.stopPropagation();
        if(selectItem === undefined) {
            if(window.confirm("프로필 등록을 취소하시겠습니까?")) {
                setGetData(getData.filter(data => data.id !== ""))
                setSelectItem(getData.filter(data => data.id !== "")[0]);
                setSelectIdx(getData.filter(data => data.id !== "")[0].id)
                itemRef.current[0].value = getData.filter(data => data.id !== "")[0]['name'].replaceAll(' ', '');
                itemRef.current[1].value = getData.filter(data => data.id !== "")[0]['enName'];
                itemRef.current[2].value = getData.filter(data => data.id !== "")[0]['birth'];
                return
            }
        }
        const delData = {
            id: id
        }
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios({
                method: "delete",
                url: process.env.REACT_APP_SERVER + '/api/admin/delete/' + field,
                data: delData,
            })
            .then((Response) => {
                if(Response.data.state === "success") {
                    const newData = getData.filter(data => data.id !== id);
                    setGetData(newData);
                    if(newData.length !== 0) {
                        setDataNum(0)
                        setSelectIdx(newData[0].id)
                        itemRef.current[0].value = newData[0]['name'].replaceAll(' ', '');
                        itemRef.current[1].value = newData[0]['enName'];
                        itemRef.current[2].value = newData[0]['birth'];
                    } else {
                        itemRef.current[0].value = "";
                        itemRef.current[1].value = "";
                        itemRef.current[2].value = "";
                    }
                    alert("삭제가 완료되었습니다.");
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
        }
    }

    function deleteImg(e, idx) {
        //이미지 보여지는곳에서 이미지만 목록에서 삭제
        e.stopPropagation();
        e.currentTarget.childNodes[0].src = ""
        imgFileArr = imgFileArr.filter((file) => file.name !== e.currentTarget.childNodes[0].name);
        for(var i=idx; i<19; i++) {
            if(imgRef.current[i+1].style.display !== "none") {
                imgRef.current[i].src = imgRef.current[i+1].src;
                imgRef.current[i].style.display = "";
                imgRef.current[i+1].style.display = "none";
                imgRef.current[i].name = imgRef.current[i+1].name;
                imgRef.current[i+1].name = "";
                inputRef.current[i].value = null;
            } else {
                imgRef.current[i].style.display = "none";
                break;
            }
        }
    }

    function createItem() {
        if(selectItem === undefined && getData.length !== 0) {
            alert("현재 입력중인 데이터가 있습니다.");
            return
        }
        const addData = {
            id: "",
            theme: "",
            enTheme: "",
            year: "",
            imgUrl: ""
        }
        setGetData([addData, ...getData]);
        setDataNum(0);
        setSelectIdx(null)
        setSelectItem();
        itemRef.current[0].value = ""
        itemRef.current[1].value = ""
        itemRef.current[2].value = ""
        for(var i=0; i<20; i++) {
            imgRef.current[i].src = ""
            imgRef.current[i].style.display = "none";
            inputRef.current[i].value = null;
        }
        for(i=1; i<15; i++) {
            careerRef.current[i].value = "";
        }
    }

    function addItem() {
        if(
            itemRef.current[0].value === "" ||
            itemRef.current[1].value === "" ||
            itemRef.current[2].value === "" ||
            imgRef.current[0].src === "http://localhost:3000/" + field
        ) {
            alert("필수 정보를 입력해 주세요.")
            return
        }
        if(selectItem === undefined) {
            const data = JSON.stringify({
                field: field,
                name: (Array.from(itemRef.current[0].value)).join(' '),
                enName : itemRef.current[1].value,
                birth: itemRef.current[2].value,
                enBirth: engBirth(itemRef.current[2].value),
                career: {
                    award: careerRef.current[1].value.replaceAll('\'', '\\\''),
                    enAward: careerRef.current[2].value.replaceAll('\'', '\\\''),
                    film: careerRef.current[3].value.replaceAll('\'', '\\\''),
                    enFilm: careerRef.current[4].value.replaceAll('\'', '\\\''),
                    drama: careerRef.current[5].value.replaceAll('\'', '\\\''),
                    enDrama: careerRef.current[6].value.replaceAll('\'', '\\\''),
                    cf: careerRef.current[7].value.replaceAll('\'', '\\\''),
                    enCf: careerRef.current[8].value.replaceAll('\'', '\\\''),
                    theater: careerRef.current[9].value.replaceAll('\'', '\\\''),
                    enTheater: careerRef.current[10].value.replaceAll('\'', '\\\''),
                    show: careerRef.current[11].value.replaceAll('\'', '\\\''),
                    enShow: careerRef.current[12].value.replaceAll('\'', '\\\''),
                    musical: careerRef.current[13].value.replaceAll('\'', '\\\''),
                    enMusical: careerRef.current[14].value.replaceAll('\'', '\\\''),
                    broadcast: careerRef.current[15].value.replaceAll('\'', '\\\''),
                    enBroadcast: careerRef.current[16].value.replaceAll('\'', '\\\''),
                    video: careerRef.current[17].value.replaceAll('\'', '\\\''),
                    enVideo: careerRef.current[18].value.replaceAll('\'', '\\\''),
                    fashion: careerRef.current[19].value.replaceAll('\'', '\\\''),
                    enFashion: careerRef.current[20].value.replaceAll('\'', '\\\''),
                    entertainment: careerRef.current[21].value.replaceAll('\'', '\\\''),
                    enEntertainment: careerRef.current[22].value.replaceAll('\'', '\\\''),
                    webdrama: careerRef.current[23].value.replaceAll('\'', '\\\''),
                    enWebdrama: careerRef.current[24].value.replaceAll('\'', '\\\'')
                }
            })
            const formData = new FormData();
            formData.set('data', data);
            imgFileArr !== null && imgFileArr.map((file) => (
                formData.append('imgfile', file)
            ))
            axios({
                method: "post",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: process.env.REACT_APP_SERVER + "/api/admin/add/" + field,
                data: formData,
            })
            .then((Response) => {
                if(Response.data.state === "success") {
                    axios.get(process.env.REACT_APP_SERVER + '/api/data/' + field)
                    .then((Response2) => {
                        setGetData(Response2.data.data)
                        if(Response2.data.data.length !== 0) {
                            itemRef.current[0].value = (Response2.data.data[0].name).replaceAll(' ', '')
                            itemRef.current[1].value = Response2.data.data[0].enName;
                            itemRef.current[2].value = Response2.data.data[0].birth;
                            setDataNum(0)
                            setSelectIdx(Response2.data.data[0].id)
                            imgFileArr = [];
                        }
                        alert("추가가 완료되었습니다.");
                    })
                    .catch((Error) => {
                        console.log(Error)
                    })
                } else {
                    alert(Response.data.message);
                }
            })
            .catch((Error) => {
                console.log(Error);
            })
        } else {
            fixData();
        }
    }

    function changeTarget(data, num) {
        if(selectItem === undefined) {
            if(window.confirm("프로필 등록을 취소하시겠습니까?")) {
                setGetData(getData.filter(data => data.id !== ""))
                setSelectItem(getData.filter(data => data.id !== "")[0]);
                setSelectIdx(getData.filter(data => data.id !== "")[0].id)
                itemRef.current[0].value = getData.filter(data => data.id !== "")[0]['name'].replaceAll(' ', '');
                itemRef.current[1].value = getData.filter(data => data.id !== "")[0]['enName'];
                itemRef.current[2].value = getData.filter(data => data.id !== "")[0]['birth'];
            }
            return
        }
        changeData(data.id, num);
        itemRef.current[0].value = (data.name).replaceAll(' ', '')
        itemRef.current[1].value = data.enName;
        itemRef.current[2].value = data.birth;
        setDataNum(num)
        setSelectIdx(data.id)
    }

    function changeData(id, num) {
        var imgCount = 0;
        for(var i=0; i<20; i++) {
            if(imgRef.current[i].style.display === "none") {
                imgCount = i;
                break
            }
        }
        if(
            itemRef.current[0].value !== (selectItem.name).replaceAll(' ', '') ||
            itemRef.current[1].value !== selectItem.enName ||
            itemRef.current[2].value !== selectItem.birth ||
            careerRef.current[1].value !== (selectItem.career[0].content === null ? "" : selectItem.career[0].content) ||
            careerRef.current[2].value !== (selectItem.career[0].enContent === null ? "" : selectItem.career[0].enContent) ||
            careerRef.current[3].value !== (selectItem.career[1].content === null ? "" : selectItem.career[1].content) ||
            careerRef.current[4].value !== (selectItem.career[1].enContent === null ? "" : selectItem.career[1].enContent) ||
            careerRef.current[5].value !== (selectItem.career[2].content === null ? "" : selectItem.career[2].content) ||
            careerRef.current[6].value !== (selectItem.career[2].enContent === null ? "" : selectItem.career[2].enContent) ||
            careerRef.current[7].value !== (selectItem.career[3].content === null ? "" : selectItem.career[3].content) ||
            careerRef.current[8].value !== (selectItem.career[3].enContent === null ? "" : selectItem.career[3].enContent) ||
            careerRef.current[9].value !== (selectItem.career[4].content === null ? "" : selectItem.career[4].content) ||
            careerRef.current[10].value !== (selectItem.career[4].enContent === null ? "" : selectItem.career[4].enContent) ||
            careerRef.current[11].value !== (selectItem.career[5].content === null ? "" : selectItem.career[5].content) ||
            careerRef.current[12].value !== (selectItem.career[5].enContent === null ? "" : selectItem.career[5].enContent) ||
            careerRef.current[13].value !== (selectItem.career[6].content === null ? "" : selectItem.career[6].content) ||
            careerRef.current[14].value !== (selectItem.career[6].enContent === null ? "" : selectItem.career[6].enContent) ||
            careerRef.current[15].value !== (selectItem.career[7].content === null ? "" : selectItem.career[7].content) ||
            careerRef.current[16].value !== (selectItem.career[7].enContent === null ? "" : selectItem.career[7].enContent) ||
            careerRef.current[17].value !== (selectItem.career[8].content === null ? "" : selectItem.career[8].content) ||
            careerRef.current[18].value !== (selectItem.career[8].enContent === null ? "" : selectItem.career[8].enContent) ||
            careerRef.current[19].value !== (selectItem.career[9].content === null ? "" : selectItem.career[9].content) ||
            careerRef.current[20].value !== (selectItem.career[9].enContent === null ? "" : selectItem.career[9].enContent) ||
            careerRef.current[21].value !== (selectItem.career[10].content === null ? "" : selectItem.career[10].content) ||
            careerRef.current[22].value !== (selectItem.career[10].enContent === null ? "" : selectItem.career[10].enContent) ||
            careerRef.current[23].value !== (selectItem.career[11].content === null ? "" : selectItem.career[11].content) ||
            careerRef.current[24].value !== (selectItem.career[11].enContent === null ? "" : selectItem.career[11].enContent) ||
            imgFileArr.length !== 0 ||
            imgCount !== selectItem.imgUrl.length
        ) {
            fixData(id, num);
        }
    }

    function fixData(id, num) {
        if(window.confirm("변경한 내용을 저장하시겠습니까?")) {
            const data = JSON.stringify({
                field: field,
                id: selectIdx,
                name: (Array.from(itemRef.current[0].value)).join(' '),
                enName : itemRef.current[1].value,
                birth: itemRef.current[2].value,
                enBirth: engBirth(itemRef.current[2].value),
                career: {
                    award: careerRef.current[1].value.replaceAll('\'', '\\\''),
                    enAward: careerRef.current[2].value.replaceAll('\'', '\\\''),
                    film: careerRef.current[3].value.replaceAll('\'', '\\\''),
                    enFilm: careerRef.current[4].value.replaceAll('\'', '\\\''),
                    drama: careerRef.current[5].value.replaceAll('\'', '\\\''),
                    enDrama: careerRef.current[6].value.replaceAll('\'', '\\\''),
                    cf: careerRef.current[7].value.replaceAll('\'', '\\\''),
                    enCf: careerRef.current[8].value.replaceAll('\'', '\\\''),
                    theater: careerRef.current[9].value.replaceAll('\'', '\\\''),
                    enTheater: careerRef.current[10].value.replaceAll('\'', '\\\''),
                    show: careerRef.current[11].value.replaceAll('\'', '\\\''),
                    enShow: careerRef.current[12].value.replaceAll('\'', '\\\''),
                    musical: careerRef.current[13].value.replaceAll('\'', '\\\''),
                    enMusical: careerRef.current[14].value.replaceAll('\'', '\\\''),
                    broadcast: careerRef.current[15].value.replaceAll('\'', '\\\''),
                    enBroadcast: careerRef.current[16].value.replaceAll('\'', '\\\''),
                    video: careerRef.current[17].value.replaceAll('\'', '\\\''),
                    enVideo: careerRef.current[18].value.replaceAll('\'', '\\\''),
                    fashion: careerRef.current[19].value.replaceAll('\'', '\\\''),
                    enFashion: careerRef.current[20].value.replaceAll('\'', '\\\''),
                    entertainment: careerRef.current[21].value.replaceAll('\'', '\\\''),
                    enEntertainment: careerRef.current[22].value.replaceAll('\'', '\\\''),
                    webdrama: careerRef.current[23].value.replaceAll('\'', '\\\''),
                    enWebdrama: careerRef.current[24].value.replaceAll('\'', '\\\'')
                },
                originImg: selectItem.imgUrl
            })
            for(var i=0; i<20;i++) {
                if(imgRef.current[i].src !== null) {
                    if(imgRef.current[i].src.indexOf("https://") !== -1) {
                        var imgName = decodeURI(imgRef.current[i].src);
                        var fileName = imgName.split('/')[imgName.split('/').length - 1];
                        var a = blobCreationFromURL(imgName);
                        const file = new File([a], fileName)
                        imgFileArr.push(file)
                    }
                }
            }
            const formData = new FormData();
            formData.set('data', data);
            imgFileArr !== null && imgFileArr.map((file) => (
                formData.append('imgfile', file)
            ))
            axios({
                method: "put",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: process.env.REACT_APP_SERVER + "/api/admin/put/" + field,
                data: formData,
            })
            .then((Response) => {
                if(Response.data.state === "success") {
                    axios.get(process.env.REACT_APP_SERVER + '/api/data/' + field)
                    .then((Response) => {
                        setGetData(Response.data.data)
                    })
                    .catch((Error) => {
                        console.log(Error)
                    })
                    if(id === undefined && num === undefined) {
                        axios.get(process.env.REACT_APP_SERVER + '/api/data/' + field + '/' + selectIdx)
                        .then((Response) => {
                            setSelectItem(Response.data.data[0])
                            Response.data.data[0].career.forEach((career, idx) => {
                                careerRef.current[2*(idx+1)-1].value = career.content === null ? "" : career.content;
                                careerRef.current[2*(idx+1)].value = career.enContent === null ? "" : career.enContent;
                            })
                            for(var i=0; i<20; i++) {
                                if(Response.data.data[0].imgUrl[i] !== undefined) {
                                    imgRef.current[i].src = Response.data.data[0].imgUrl[i].imgUrl;
                                    imgRef.current[i].style.display = "";
                                } else {
                                    imgRef.current[i].style.display = "none";
                                    imgRef.current[i].src = null;
                                }
                                inputRef.current[i].value = null;
                            }
                        })
                        .catch((Error) => {
                            console.log(Error)
                        })
                    }
                    imgFileArr = [];
                    alert("저장되었습니다.")
                }
            })
        }
    }

    function engBirth(birth) {
        birth = birth.replaceAll(' ', '').replace(/[^0-9\s]/g, ",");
        var birthArr = birth.split(',');
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return month[birthArr[1] - 1] + " " + birthArr[2] + ", " + birthArr[0]
    }

    function previewImage(idx) {
        if (!inputRef.current[idx]) {
            return;
        }
        inputRef.current[idx].click();
    }

    function onUploadImage(e) {
        if (e.target.value.length === 0) {
            return;
        } else if(e.target.files[0].size > e.target.size) {
            alert("파일 용량이 10MB를 초과합니다.");
            return
        } else {
            if(inArray(imgFileArr, e.target.files[0])) {
                alert("동일한 파일명이 존재합니다.");
                return
            }
            let reader = new FileReader();

            if(e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
            }

            reader.onloadend = function() {
                for(var i=0; i<20; i++) {
                    if(imgRef.current[i].style.display === "none") {
                        imgRef.current[i].src = reader.result;
                        imgRef.current[i].style.display = "";
                        imgFileArr.push(e.target.files[0]);
                        imgRef.current[i].name = e.target.files[0].name;
                        break;
                    }
                }
            }
        }
    }

    function blobCreationFromURL(inputURI) {
  
        var binaryVal;
        var inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

        if (inputURI.split(',')[0].indexOf('base64') >= 0){
            binaryVal = atob(inputURI.split(',')[1]);
        } else {
            binaryVal = unescape(inputURI.split(',')[1]);
        }
        var blobArray = [];
        for (var index = 0; index < binaryVal.length; index++) {
            blobArray.push(binaryVal.charCodeAt(index));
        }
  
        return new Blob([blobArray], {
            type: inputMIME
        });
    }

    function inArray(arr, val) {
        for(var i=0; i<arr.length; i++) {
            if((arr[i].name).indexOf(val.name) !== -1) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/api/data/' + field)
        .then((Response) => {
            setGetData(Response.data.data)
            if(Response.data.data.length !== 0) {
                itemRef.current[0].value = (Response.data.data[0].name).replaceAll(' ', '')
                itemRef.current[1].value = Response.data.data[0].enName;
                itemRef.current[2].value = Response.data.data[0].birth;
                setDataNum(0)
                setSelectIdx(Response.data.data[0].id)
            }
        })
        .catch((Error) => {
            console.log(Error)
        })
    }, [])

    useEffect(() => {
        if(selectIdx != null) {
            axios.get(process.env.REACT_APP_SERVER + '/api/data/' + field + '/' + selectIdx)
            .then((Response) => {
                setSelectItem(Response.data.data[0])
                Response.data.data[0].career.forEach((career, idx) => {
                    careerRef.current[2*(idx+1)-1].value = career.content === null ? "" : career.content;
                    careerRef.current[2*(idx+1)].value = career.enContent === null ? "" : career.enContent;
                })
                for(var i=0; i<20; i++) {
                    if(Response.data.data[0].imgUrl[i] !== undefined) {
                        imgRef.current[i].src = Response.data.data[0].imgUrl[i].imgUrl;
                        imgRef.current[i].style.display = "";
                    } else {
                        imgRef.current[i].style.display = "none";
                        imgRef.current[i].src = null;
                    }
                    inputRef.current[i].value = null;
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
        }
    }, [selectIdx])

    return(
        <div className={ `${style.container} ${style[field]}` }>
            <div className={ style.topContainer }>
                <span>{ columnK[field][0] + " 프로필 관리" }</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ style.middleContainer}>
                <div className={ style.middleArtistWrap }>
                    <div className={ style.middleArtist }>
                        <div className={ style.themeContainer }>
                            <span className={ style.middleTheme }>목록</span>
                            <hr className={ style.middleLine }></hr>
                        </div>
                        <div className={ style.artistContent }>
                            <div className={ style.artistListContainer }>
                                {
                                    getData.map((data, idx) => (
                                        <div key={ data.id } className={ dataNum === idx ? `${style.artistListItem} ${style.selected}` : style.productionListItem } onClick={ () => changeTarget(data, idx) }>
                                            <div className={ style.artistListItemNum }>
                                                <span>{idx+1}</span>
                                            </div>
                                            <div className={ style.artistListItemName }>
                                                <span>{data.name}</span>
                                            </div>
                                            <div className={ style.deleteItemBtn } onClick={ (e) => deleteItem(e, data.id) }>
                                                <div>
                                                    <hr></hr>
                                                    <hr></hr>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className={ `${style.actionButton2} ${style.fncProductionBtn}` } onClick={ createItem }>
                                프로필 추가
                            </div>
                        </div>
                    </div>
                    <div className={ style.middleArtist }>
                        <div className={ style.themeContainer }>
                            <span className={ style.middleTheme }>프로필 등록/수정</span>
                            <hr className={ style.middleLine }></hr>
                        </div>
                        <div className={ style.artistContent2 }>
                            <div className={ style.artistContentInfo }>
                                <div className={ style.contentInfoContainer }>
                                    <span className={ style.artistContentTheme } >{ columnK[field][1] + " 정보"}</span>
                                </div>
                                <div className={ style.infoItemContainer }>
                                    <div className={ style.infoItem }>
                                        <span>국문이름<span className={ style.required }>*필수</span></span>
                                        <input type="text" ref={ el => (itemRef.current[0] = el) } placeholder="홍길동"></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>영문이름<span className={ style.required }>*필수</span></span>
                                        <input type="text" ref={ el => (itemRef.current[1] = el) } placeholder="Gil-dong Hong"></input>
                                    </div>
                                    <div className={ style.infoItem }>
                                        <span>생년월일<span className={ style.required }>*필수</span></span>
                                        <input type="text" ref={ el => (itemRef.current[2] = el) } placeholder="1990년 1월 23일"></input>
                                    </div>
                                </div>
                            </div>
                            <div className={ style.artistContentImage }>
                                <div className={ style.artistContentInfoContainer }>
                                    <span className={ style.artistContentTheme }>상세 이미지</span>
                                    <div className={ style.aritstContentExplain }>
                                        <span>- 2:3 비율로 최소 640*960px 이상의 이미지 업로드를 권장합니다. (다른 비율로 업로드 시 이미지 비율 훼손)</span>
                                        <span>- 10mb 미만의 이미지만 업로드할 수 있습니다.</span>
                                        <span>- 최대 20장까지 업로드 가능합니다.</span>
                                    </div>
                                </div>
                                <div className={ style.artistImgContainer }>
                                    {
                                        imgArr.map((item, idx) => (
                                            <div key={ item.id } className={ style.artistImgItem } onClick={ () => previewImage(idx) }>
                                                <div className={ style.artistImgWithDel } onClick={ (e) => deleteImg(e, idx) }>
                                                    <img ref={ el => (imgRef.current[idx] = el) } alt="artistImage"></img>
                                                    <div className={ style.deleteItemBtn }>
                                                        <div>
                                                            <hr></hr>
                                                            <hr></hr>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={ style.itemNameTag }>{ item.tag }</div>
                                                <input type="file" accept="image/*" ref={ el => (inputRef.current[idx] = el) } onChange={ onUploadImage } size="10485760"></input>
                                                <div className={ style.artistPlus }>
                                                    <hr className={ style.plusShape2 }></hr>
                                                    <hr className={ style.plusShape2 }></hr>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={ style.artistContentCareer }>
                                <div className={ style.artistContentInfoContainer }>
                                    <span className={ style.artistContentTheme }>이력사항</span>
                                    <div className={ style.aritstContentExplain }>
                                        <span>- 좌측은 국문, 우측은 영문 버전에 보이는 이력사항입니다.</span>
                                        <span>- 각 항목당 최대 글자 수는 공백 포함 1,000자입니다.</span>
                                        <span>- 각 항목은 ‘,’ 컴마로 구분됩니다.</span>
                                    </div>
                                </div>
                                <div className={ style.artistCareerContainer }>
                                    {
                                        careerArr.map((item, idx) => (
                                            <div key={ idx } className={ style.artistCareerItem }>
                                                <span>{item}</span>
                                                <div className={ style.artistCareerTextarea }>
                                                    <textarea ref={ el => (careerRef.current[idx+1] = el) } maxLength="1000"></textarea>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={ addItem }>
                    <span>저장하기</span>
                </div>
            </div>
        </div>
    )
}