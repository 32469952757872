import { useRef, useState, useEffect } from "react";
import axios from "axios";
import style from "../css/AdminModal.module.css";
import exit from "../img/component/admin_exit.svg";

export default function AdminNews({ setShowAdmin, field, originData, setRender }) {
    const columnK = {
        news: "뉴스",
        notice: "공지사항",
    }
    const inputRef = useRef([]);
    const inputImgRef = useRef();
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const inputFileRef = useRef();
    const [downloadFile, setDownloadFile] = useState(null);

    function closeAdmin() {
        setRender(new Date())
        setShowAdmin(false);
    }

    function previewImage() {
        if (!inputImgRef.current) {
            return;
        }
        inputImgRef.current.click();
    }

    function previewFile() {
        if (!inputFileRef.current) {
            return;
        }
        inputFileRef.current.click();
    }

    function onUploadImage(e) {
        if (e.target.value.length === 0) {
            return
        } else if(e.target.files[0].size > e.target.size) {
            alert("파일 용량이 10MB를 초과합니다.");
            return
        } else {
            let reader = new FileReader();

            if(e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                setImageFile(e.target.files[0]);
            }
    
            reader.onloadend = function() {
                setImageSrc(reader.result);
            }
        }
    };

    function onUploadFile(e) {
        if (e.target.value.length === 0) {
            return
        }

        if(e.target.files.length + (downloadFile !== null && downloadFile.length) > 3) {
            alert("파일은 최대 3개 첨부가능합니다.")
            return
        } else if(e.target.files.size > e.target.size) {
            alert("파일 용량이 20MB를 초과합니다.");
        } else {
            if(downloadFile !== null) {
                for(var i=0; i<downloadFile.length; i++) {
                    for(var j=0; j<Array.from(e.target.files).length; j++) {
                        if(downloadFile[i].name === Array.from(e.target.files)[j].name) {
                            alert('중복된 파일이 존재합니다.');
                            return
                        }
                    }
                }
                setDownloadFile([...downloadFile, ...Array.from(e.target.files)])
            } else {
                setDownloadFile(Array.from(e.target.files))
            }
            
        }
    }

    function deleteFile(e) {
        const delFile = downloadFile.filter(file =>
            originData !== undefined ?
            (inArray(originData.file, file.name) ?
                (file.name.split('.')[0].slice(0, -20) + "." + file.name.split('.')[1]).length > 20 ?
                    file.name.substr(0, 8) + "..." + file.name.substr(-8)
                :
                    file.name.split('.')[0].slice(0, -20) + "." + file.name.split('.')[1]
            :
                file.name.length > 20 ?
                    file.name.substr(0, 8) + "..." + file.name.substr(-8)
                :
                    file.name
            )
            :
                file.name.length > 20 ?
                    file.name.substr(0, 8) + "..." + file.name.substr(-8)
                :
                    file.name
        !==
            e.currentTarget.parentNode.childNodes[0].innerHTML)
        setDownloadFile(delFile)
        const dataTransfer = new DataTransfer();
        delFile.map((file) => dataTransfer.items.add(file));
        inputFileRef.current.files = dataTransfer.files;
    }

    function addData() {
        if(
            inputRef.current[0].value === "" ||
            inputRef.current[1].value === "" ||
            inputRef.current[2].value === "" ||
            inputRef.current[3].value === "" ||
            inputRef.current[6].value === "" ||
            inputRef.current[7].value === ""
        ) {
            alert("필수 항목을 모두 입력해주세요.");
            return
        } else {
            if(field === "news") {
                if(imageFile === null) {
                    alert("필수 항목을 모두 입력해주세요.");
                    return
                }
                const tagArr = [];
                if(inputRef.current[4].value !== "") {
                    inputRef.current[4].value.replaceAll(' ', '').split(',').map((tag, idx) => {
                        tagArr.push({
                            id: idx+1,
                            name: tag,
                            enName: inputRef.current[5].value.replaceAll(' ', '').split(',')[idx]
                        })
                    })
                }
                const newData = JSON.stringify({
                    name: field,
                    theme: inputRef.current[0].value.replaceAll('\'', '\\\''),
                    enTheme: inputRef.current[1].value.replaceAll('\'', '\\\''),
                    date: inputRef.current[2].value,
                    enDate: inputRef.current[3].value,
                    tag: tagArr,
                    content: inputRef.current[6].value.replaceAll('\'', '\\\''),
                    enContent: inputRef.current[7].value.replaceAll('\'', '\\\''),
                    link: ""
                })
                const formData = new FormData();
                formData.set('file', imageFile);
                formData.set('data', newData);
                axios({
                    method: "post",
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    url: process.env.REACT_APP_SERVER + "/api/admin/add/" + field,
                    data: formData,
                })
                .then((Response) => {
                    if(Response.data.state === "success") {
                        inputRef.current.map((input) => {
                            input.value = "";
                        })
                        inputImgRef.current.value = ""
                        setImageFile(null)
                        setImageSrc(null);
                        alert("게시물이 추가되었습니다.")
                    } else {
                        alert(Response.data.message);
                    }
                })
                .catch((Error) => {
                    console.log(Error);
                })
                return
            } else if(field === "notice") {
                const newData = JSON.stringify({
                    name: field,
                    theme: inputRef.current[0].value.replaceAll('\'', '\\\''),
                    enTheme: inputRef.current[1].value.replaceAll('\'', '\\\''),
                    date: inputRef.current[2].value,
                    enDate: inputRef.current[3].value,
                    content: inputRef.current[6].value.replaceAll('\'', '\\\''),
                    enContent: inputRef.current[7].value.replaceAll('\'', '\\\'')
                })
                const formData = new FormData();
                formData.set('imgfile', imageFile !== null ? imageFile : "");
                downloadFile !== null && downloadFile.map((file) => {
                    formData.append('downloadfile', file);
                })
                formData.set('data', newData);
                axios({
                    method: "post",
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    url: process.env.REACT_APP_SERVER + "/api/admin/add/" + field,
                    data: formData,
                })
                .then((Response) => {
                    if(Response.data.state === "success") {
                        inputRef.current.map((input) => {
                            input.value = "";
                        })
                        inputImgRef.current.value = ""
                        setImageFile(null);
                        setImageSrc(null);
                        setDownloadFile(null);
                        alert("게시물이 추가되었습니다.")
                    } else {
                        alert(Response.data.message);
                    }
                })
                .catch((Error) => {
                    console.log(Error);
                })
                return
            }
        }
    }

    function fixData() {
        if(
            inputRef.current[0].value === "" ||
            inputRef.current[1].value === "" ||
            inputRef.current[2].value === "" ||
            inputRef.current[3].value === "" ||
            inputRef.current[6].value === "" ||
            inputRef.current[7].value === ""
        ) {
            alert("필수 항목을 모두 입력해주세요.");
            return
        } else {
            if(field === "news") {
                if(imageFile === null) {
                    alert("필수 항목을 모두 입력해주세요.");
                    return
                }
                const tagArr = [];
                if(inputRef.current[4].value !== "") {
                    inputRef.current[4].value.replaceAll(' ', '').split(',').map((tag, idx) => {
                        tagArr.push({
                            id: idx+1,
                            name: tag,
                            enName: inputRef.current[5].value.replaceAll(' ', '').split(',')[idx]
                        })
                    })
                }
                const newData = JSON.stringify({
                    id: originData.id,
                    name: field,
                    theme: inputRef.current[0].value.replaceAll('\'', '\\\''),
                    enTheme: inputRef.current[1].value.replaceAll('\'', '\\\''),
                    date: inputRef.current[2].value,
                    enDate: inputRef.current[3].value,
                    tag: tagArr,
                    content: inputRef.current[6].value.replaceAll('\'', '\\\''),
                    enContent: inputRef.current[7].value.replaceAll('\'', '\\\''),
                    link: "",
                    beforeImgUrl: originData.imgUrl
                })
                const formData = new FormData();
                formData.set('imgfile', imageFile !== null ? imageFile : "");
                formData.set('data', newData);
                axios({
                    method: "put",
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    url: process.env.REACT_APP_SERVER + "/api/admin/put/" + field,
                    data: formData,
                })
                .then((Response) => {
                    if(Response.data.state === "success") {
                        // inputRef.current.map((input) => {
                        //     input.value = "";
                        // })
                        // inputImgRef.current.value = ""
                        // setImageFile(null)
                        // setImageSrc(null);
                        alert("게시물이 수정되었습니다.")
                    } else {
                        alert(Response.data.message);
                    }
                })
                .catch((Error) => {
                    console.log(Error);
                })
                return
            } else if(field === "notice") {
                const newData = JSON.stringify({
                    id: originData.id,
                    name: field,
                    theme: inputRef.current[0].value.replaceAll('\'', '\\\''),
                    enTheme: inputRef.current[1].value.replaceAll('\'', '\\\''),
                    date: inputRef.current[2].value,
                    enDate: inputRef.current[3].value,
                    content: inputRef.current[6].value.replaceAll('\'', '\\\''),
                    enContent: inputRef.current[7].value.replaceAll('\'', '\\\''),
                    beforeImgUrl: originData.imgUrl,
                    beforeFile: originData.file
                })
                const formData = new FormData();
                formData.set('imgfile', imageFile !== null ? imageFile : "");
                downloadFile !== null && downloadFile.map((file) => {
                    formData.append('downloadfile', file);
                })
                formData.set('data', newData);
                axios({
                    method: "put",
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    url: process.env.REACT_APP_SERVER + "/api/admin/put/" + field,
                    data: formData,
                })
                .then((Response) => {
                    if(Response.data.state === "success") {
                        // inputRef.current.map((input) => {
                        //     input.value = "";
                        // })
                        // inputImgRef.current.value = ""
                        // setImageFile(null);
                        // setImageSrc(null);
                        // setDownloadFile(null);
                        alert("게시물이 수정되었습니다.")
                    } else {
                        alert(Response.data.message);
                    }
                })
                .catch((Error) => {
                    console.log(Error);
                })
                return
            }
        }
    }

    function blobCreationFromURL(inputURI) {
  
        var binaryVal;
        var inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

        if (inputURI.split(',')[0].indexOf('base64') >= 0){
            binaryVal = atob(inputURI.split(',')[1]);
        } else {
            binaryVal = unescape(inputURI.split(',')[1]);
        }
        var blobArray = [];
        for (var index = 0; index < binaryVal.length; index++) {
            blobArray.push(binaryVal.charCodeAt(index));
        }
  
        return new Blob([blobArray], {
            type: inputMIME
        });
    }

    function inArray(arr, val) {
        for(var i=0; i<arr.length; i++) {
            if((arr[i].name).indexOf(val) !== -1) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        if(originData !== undefined) {
            if(field === "notice") {
                inputRef.current[0].value = originData.theme
                inputRef.current[1].value = originData.enTheme
                inputRef.current[2].value = originData.date
                inputRef.current[3].value = originData.enDate
                inputRef.current[6].value = originData.content
                inputRef.current[7].value = originData.enContent

                if(originData.imgUrl !== null) {
                    var fileName = originData.imgUrl.split('/')[originData.imgUrl.split('/').length - 1];
                    var a = blobCreationFromURL(originData.imgUrl);
                    const file = new File([a], fileName)
                    setImageFile(file)

                }
                if(originData.file.length !== 0) {
                    const originDownloadFile = [];
                    originData.file.map((item, idx) => {
                        var downloadName = item.name.split('/')[item.name.split('/').length - 1];
                        var a = blobCreationFromURL(item.name);
                        const file = new File([a], downloadName)
                        originDownloadFile.push(file)
                    })
                    setDownloadFile(originDownloadFile)
                }
            } else if(field === "news") {
                function getTag(arr) {
                    var tagString = ""
                    arr.map((tag, idx) => {
                        if(idx !== 0) {
                            tagString += ", " + tag.name
                        } else {
                            tagString = tag.name
                        }
                    })
                    return tagString
                }

                inputRef.current[0].value = originData.theme
                inputRef.current[1].value = originData.enTheme
                inputRef.current[2].value = originData.date
                inputRef.current[3].value = originData.enDate
                inputRef.current[4].value = getTag(originData.tag)
                inputRef.current[5].value = getTag(originData.en_tag)
                inputRef.current[6].value = originData.content
                inputRef.current[7].value = originData.enContent

                if(originData.imgUrl !== null) {
                    var fileName = originData.imgUrl.split('/')[originData.imgUrl.split('/').length - 1];
                    var a = blobCreationFromURL(originData.imgUrl);
                    const file = new File([a], fileName)
                    setImageFile(file)

                }
            }
        }
    }, [originData])

    return(
        <div className={ `${style.container} ${style[field]}` }>
            <div className={ style.topContainer }>
                <span>{ columnK[field] + " 게시물 관리" }</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ style.middleContainer }>
                <div className={ style.themeContainer }>
                    <span className={ style.middleTheme }>게시물 등록/수정</span>
                    <hr className={ style.middleLine }></hr>
                </div>
                <div className={ style.newsContent }>
                    <div className={ style.newsContentItem }>
                        <div className={ style.newsContentThemeContainer }>
                            <span className={ style.newsContentTheme }>제목<div className={ style.requirednews }>*필수</div></span>
                            <span className={ style.newsContentexplain }>- 국문 버전에 보여집니다. 공백 포함 50자 내</span>
                        </div>
                        <input type="text" ref={ el => (inputRef.current[0] = el) }></input>
                    </div>
                    <div className={ style.newsContentItem }>
                        <div className={ style.newsContentThemeContainer }>
                            <span className={ style.newsContentTheme }>Title<div className={ style.requirednews }>*필수</div></span>
                            <span className={ style.newsContentexplain }>- 영문 버전에 보여집니다. 공백 포함 50자 내</span>
                        </div>
                        <input type="text" ref={ el => (inputRef.current[1] = el) } ></input>
                    </div>
                    <div className={ style.newsContentItem }>
                        <div className={ style.newsContentThemeContainer }>
                            <span className={ style.newsContentTheme }>날짜<div className={ style.requirednews }>*필수</div></span>
                            <span className={ style.newsContentexplain }>- 국문 버전에 보여집니다.</span>
                        </div>
                        <input type="text" placeholder="예시) 2023-01-10" ref={ el => (inputRef.current[2] = el) } ></input>
                    </div>
                    <div className={ style.newsContentItem }>
                        <div className={ style.newsContentThemeContainer }>
                            <span className={ style.newsContentTheme }>Date<div className={ style.requirednews }>*필수</div></span>
                            <span className={ style.newsContentexplain }>- 영문 버전에 보여집니다.</span>
                        </div>
                        <input type="text" placeholder="ex) Jan 10, 2023" ref={ el => (inputRef.current[3] = el) } ></input>
                    </div>
                    {
                        field === "news" &&
                        <div className={ style.newsContentItem }>
                            <div className={ style.newsContentThemeContainer }>
                                <span className={ style.newsContentTheme }>해시태그</span>
                                <span className={ style.newsContentexplain }>- ‘ , ’기호로 구분</span>
                            </div>
                            <input type="text" placeholder="예시) 태그, #해시태그, #예시" ref={ el => (inputRef.current[4] = el) } ></input>
                        </div>
                    }
                    {
                        field === "news" &&
                        <div className={ style.newsContentItem }>
                            <div className={ style.newsContentThemeContainer }>
                                <span className={ style.newsContentTheme }>Hashtag</span>
                                <span className={ style.newsContentexplain }>- ‘ , ’기호로 구분</span>
                            </div>
                            <input type="text" placeholder="ex) tag, #Hashtag, #example" ref={ el => (inputRef.current[5] = el) } ></input>
                        </div>
                    }
                    <div className={ style.newsContentItem }>
                        <div className={ style.newsContentThemeContainer }>
                            <span className={ style.newsContentTheme }>내용<div className={ style.requirednews }>*필수</div></span>
                            <span className={ style.newsContentexplain }>- 국문 버전에 보여집니다. 공백 포함 5,000자 내</span>
                        </div>
                        <textarea className={ style.textarea } ref={ el => (inputRef.current[6] = el) } maxLength="5000"></textarea>
                    </div>
                    <div className={ style.newsContentItem }>
                        <div className={ style.newsContentThemeContainer }>
                            <span className={ style.newsContentTheme }>Content<div className={ style.requirednews }>*필수</div></span>
                            <span className={ style.newsContentexplain } >- 영문 버전에 보여집니다. 공백 포함 5,000자 내</span>
                        </div>
                        <textarea className={ style.textarea } ref={ el => (inputRef.current[7] = el) } maxLength="5000"></textarea>
                    </div>
                </div>
                <hr className={ style.middleLine }></hr>
                <div className= { style.newsImgContainer }>
                    <div className={ style.newsContentThemeContainer }>
                        <span className={ style.newsContentTheme }>이미지 첨부
                        {
                            field === "news" &&
                            <div className={ style.requirednews }>*필수</div>
                        }
                        </span>
                        <span className={ style.newsContentexplain }>- 최대 1장(jpg/png), 가로사이즈 최대 1200px</span>
                    </div>
                    <div className={ `${style.actionButton} ${style.newsImgAddBtn}` } onClick={ previewImage }>
                        첨부하기
                    </div>
                    {
                        imageFile &&
                        <div className={ style.newsUploadImg }>
                            <span>
                                {
                                    originData !== undefined &&
                                    originData.imgUrl !== null && originData.imgUrl.indexOf(imageFile.name) !== -1 ?
                                    imageFile.name.split('.')[0].slice(0, -20) + "." + imageFile.name.split('.')[1]
                                    :
                                    imageFile.name
                                }
                            </span>
                            <div className={ style.newsUploadImgDelBtn } onClick={ () => {setImageFile(null); setImageSrc(null); inputImgRef.current.value = null;} }>
                                <hr></hr>
                                <hr></hr>
                            </div>
                        </div>
                    }
                    <input type="file" accept=".png, .jpeg, .jpg, .svg" ref={ inputImgRef } onChange={ onUploadImage } size="10485760"></input>
                </div>
                {
                    field === "notice" &&
                    <div className= { style.newsFileContainer }>
                        <div className={ style.newsContentThemeContainer }>
                            <span className={ style.newsContentTheme }>파일 첨부</span>
                            <span className={ style.newsContentexplain }>- 최대 3개, 1개당 최대 20MB</span>
                        </div>
                        <div className={ `${style.actionButton} ${style.newsImgAddBtn}` } onClick={ previewFile }>
                            첨부하기
                        </div>
                        {
                            downloadFile !== null &&
                            downloadFile.map((file, idx) => (
                                <div key={ file.name } className={ style.newsUploadImg }>
                                    <span>
                                        {
                                            originData !== undefined &&
                                            inArray(originData.file, file.name) ?
                                            (file.name.split('.')[0].slice(0, -20) + "." + file.name.split('.')[1]).length > 20 ?
                                            file.name.substr(0, 8) + "..." + file.name.substr(-8)
                                            :
                                            file.name.split('.')[0].slice(0, -20) + "." + file.name.split('.')[1]
                                            :
                                            file.name
                                        }
                                    </span>
                                    <div className={ style.newsUploadImgDelBtn } onClick={ (e) => deleteFile(e) }>
                                        <hr></hr>
                                        <hr></hr>
                                    </div>
                                </div>
                            ))
                        }
                        <input type="file" accept="*.*" ref={ inputFileRef } onChange={ onUploadFile } size="20971520" multiple="multiple"></input>
                    </div>
                }
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={ originData === undefined ? addData : fixData }>
                    <span>등록하기</span>
                </div>
            </div>
        </div>
    )
}