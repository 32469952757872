import style from "../css/Header.module.css";
import arrow from "../img/component/arrow.svg";
import lang from "../img/component/lang.svg";
import admin from "../img/component/admin.svg";
import logo from "../img/component/header_logo.billions.svg";
import mLogo from "../img/component/mobile_header_logo.svg";
import hamLogo from "../img/component/ham_logo.svg";
import down from "../img/component/down_arrow.svg";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import AdminModal from "../PageComponents/AdminModal";
import Portal from "./Portal";
import { Transition } from "react-transition-group";

export default function Header({isEn, setIsEn, animated, setAnimated, isAdmin, setIsAdmin, showAdmin, setShowAdmin, field, setField }) {
    const topContents = [
        // { id: 1, isBold: true, link: "https://blueberrynft.co.kr/", method: "new", content: "BLADE ENT", img: arrow },
        // { id: 2, isBold: true, link: "http://topplay.io/", method: "new", content: "TOP PLAY", img: arrow },
        { id: 3, isBold: false, link: "https://dart.fss.or.kr/", method: "new", content: "FSS", img: arrow },
        { id: 4, isBold: false, link: "", method: "lang", content: isEn ? "KO" : "EN", img: lang },
        { id: 5, isBold: false, link: "", method: "modal", content: null, img: admin },
    ];
    const bottomContents = [
        { id: 1, content: "Company", isHover: false, link: "company" },
        { id: 2, content: "Production", isHover: true, hoverContent: [["Drama", "Producer"], ["Movie"], ["Album/Concert"]], link: "production"},
        { id: 3, content: "Artist", isHover: false, link: "artist" },
        { id: 4, content: "Blade Ent", isHover: false, link: "" },
        { id: 5, content: "Musician", isHover: false, link: "musician" },
        { id: 6, content: "News", isHover: true, hoverContent: [["News"], ["Notice"]], link: "news" },
        { id: 7, content: "Audition", isHover: false, link: "audition" }
    ];
    const isActive = useRef();
    const [activeLink, setActiveLink] = useState();
    const [moHeader, setmoHeader] = useState(false);
    const [hoverItem, setHoverItem] = useState({2: true, 6: true});
    const moRef = useRef();

    function linkTo(link, method) {
        if(method === "new") {
            window.open(link);
        } else if(method === "lang") {
            setIsEn(!isEn);
        } else if(method === "modal") {
            if(isAdmin) {
                alert("로그아웃이 완료되었습니다.");
                setIsAdmin(false);
                window.localStorage.removeItem("login_access")
            } else {
                setShowAdmin(!showAdmin);
                setField("login")
            }
        }
    }
    function setAnimate() {
        setActiveLink("")
        if(isActive.current.className !== "active") {
            setAnimated(Math.random());
        }
    }

    useEffect(() => {
        if(moHeader) {
            moRef.current.style.right = 0;
            moRef.current.style.opacity = 1;
        } else {
            moRef.current.style.opacity = 0;
            moRef.current.style.right = -284 + "px";
        }
    })

    return(
        <div key={ animated } id={ style.header } className={ style.wrap }>
            <div className={ style.mobileHeaderWrap }>
                <div className={ style.mobileHeaderInnerWrap}>
                    <NavLink ref={ isActive } to="" onClick={ () => { setAnimate(); setmoHeader(false)} }>
                        <img src={ logo } alt="logo" className={ style.mobileHeaderLogo }></img>
                    </NavLink>
                    <div className={ style.mobileHeaderBtn } onClick={ () => setmoHeader(true) }>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div ref={ moRef } className={ style.mobileHeader }>
                        <div className={ style.mobileHeaderClose } onClick={ () => setmoHeader(false) }>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={ style.mobileBottomContainer }>
                            {
                                bottomContents.map((bottomContent) => (
                                    bottomContent.isHover ?
                                        <div key={ bottomContent.id } className={ `${style.bottomItem} ${activeLink === bottomContent.link && "active"}`}>
                                            <div className={ style.bottomContentHover }>
                                                <span>{ bottomContent.content }</span>
                                                <img src={ down } alt="down-arrow" onClick={ () => setHoverItem({...hoverItem, [bottomContent.id]: !hoverItem[bottomContent.id]}) } className={ hoverItem[bottomContent.id] ? "" : style.close }></img>
                                            </div>
                                            <div className={ hoverItem[bottomContent.id] ? style.bottomItemSub : `${style.bottomItemSub} ${style.hide}` }>
                                                {
                                                    bottomContent.hoverContent.map((hoverContent, idx) => (
                                                        <div key={ idx } className={ style.hoverItem }>
                                                            {
                                                                hoverContent.length > 1 ?
                                                                <div className={style.doubleContent}>
                                                                    <span>{hoverContent[0]}</span>
                                                                    {
                                                                        hoverContent.map((data) => (
                                                                            <Link key={data + "-m"} to={ bottomContent.link + "?column=" + data + "&pagenum=1" } onClick={ () => {setActiveLink(bottomContent.link); setmoHeader(false);} }>
                                                                                <span>- { data }</span>
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>
                                                                :
                                                                <Link to={ bottomContent.link + "?column=" + hoverContent + "&pagenum=1" } onClick={ () => {setActiveLink(bottomContent.link); setmoHeader(false);} }>
                                                                    <span>{ hoverContent }</span>
                                                                </Link>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    :
                                        bottomContent.content !== "Blade Ent" &&
                                        <div key={ bottomContent.id } className={ style.bottomItem }>
                                            <NavLink to={ "/" + bottomContent.link } onClick={ () => {setActiveLink(bottomContent.link); setmoHeader(false);} }>
                                                { bottomContent.content }
                                            </NavLink>
                                        </div>
                                ))
                            }
                        </div>
                        <div className={ style.mobileTopContainer }>
                            {
                                topContents.map((topContent) => (
                                    <div key={ topContent.id } className={ topContent.isBold ? `${style.topItem} ${style.bold}` : style.topItem } onClick={ () => linkTo(topContent.link, topContent.method) }>
                                        {
                                            topContent.content !== null &&
                                            <span>{ topContent.content }</span>
                                        }
                                        <img src={ topContent.img } alt="Link"></img>
                                    </div>
                                ))
                            }
                        </div>
                        <img src={ hamLogo } alt="logo" className={ style.hamLogo } width={150} height={"auto"}></img>
                    </div>
                </div>
            </div>
            <div className={ style.topContainer }>
                {
                    topContents.map((topContent) => (
                        <div key={ topContent.id } className={ topContent.isBold ? `${style.topItem} ${style.bold}` : style.topItem } onClick={ () => linkTo(topContent.link, topContent.method) }>
                            {
                                topContent.content !== null &&
                                <span>{ topContent.content }</span>
                            }
                            <img src={ topContent.img } alt="Link"></img>
                        </div>
                    ))
                }
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.bottomInnerContainer }>
                    {
                        bottomContents.map((bottomContent) => (
                            bottomContent.isHover ?
                                <div key={ bottomContent.id } className={ `${style.bottomItem} ${activeLink === bottomContent.link ? "active" : ""}`}>
                                    <span>{ bottomContent.content }</span>
                                    <div className={ bottomContent.content === "Production" ? style.bottomItemSub3 : style.bottomItemSub }>
                                        {
                                            bottomContent.hoverContent.map((hoverContent, idx) => (
                                                <div key={ idx } className={ style.hoverItem }>
                                                    {
                                                        hoverContent.length > 1 ?
                                                        <div className={style.doubleContent}>
                                                            <span>{hoverContent[0]}</span>
                                                            {
                                                                hoverContent.map((data) => (
                                                                    <Link key={data} to={ bottomContent.link + "?column=" + data + "&pagenum=1" } onClick={ () => setActiveLink(bottomContent.link) }>
                                                                        <span>- {data}</span>
                                                                    </Link>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        <Link to={ bottomContent.link + "?column=" + hoverContent + "&pagenum=1" } onClick={ () => setActiveLink(bottomContent.link) }>
                                                            <span>{ hoverContent }</span>
                                                        </Link>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            :
                                <div key={ bottomContent.id } className={ style.bottomItem }>
                                {
                                    bottomContent.content === "Blade Ent" ?
                                        <NavLink ref={ isActive } to="" onClick={ setAnimate }>
                                            <img src={ logo } alt="Logo"></img>
                                        </NavLink>
                                    :
                                        <NavLink to={ "/" + bottomContent.link } onClick={ () => setActiveLink("")}>
                                            { bottomContent.content }
                                        </NavLink>
                                }
                                </div>
                        ))
                    }
                </div>
            </div>
            {
                field === "login" &&
                <Portal>
                    <Transition unmountOnExit in={ showAdmin } timeout={0}>
                        <AdminModal isAdmin={ isAdmin } setIsAdmin={ setIsAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } />
                    </Transition>
                </Portal>
            }
        </div>
    )
}