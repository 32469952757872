import './App.css';
import ScrollToTop from './Components/ScrollToTop';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Main from './Pages/Main';
import Company from './Pages/Company';
import Production from './Pages/Production';
import Artist from './Pages/Artist';
import Musician from './Pages/Musician';
import Audition from './Pages/Audition';
import News from './Pages/News';
import More from './PageComponents/More';
import SEOMeta from './PageComponents/SEOMeta';

function App() {
  const [isEn, setIsEn] = useState(window.location.pathname.indexOf("en") === -1 ? false : true);
  const [animated, setAnimated] = useState();
  const [showAdmin, setShowAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(window.localStorage.getItem("login_access") === "true" ? true : false);
  const [field, setField] = useState("");
  const [render, setRender] = useState();

  return (
    <div className="wrapper">
      <BrowserRouter>
        <ScrollToTop />
        <AnimatePresence>
          <Header key="header" isEn={ isEn } setIsEn={ setIsEn } animated={ animated } setAnimated={ setAnimated } isAdmin={ isAdmin } setIsAdmin={ setIsAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setField={ setField } />
          <Routes key="routes">
            <Route key="main" path="" element={ <Main isEn={ isEn } isAdmin={ isAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setField={ setField } render={ render } setRender={ setRender } /> }></Route>
            <Route key="company" path="/company" element={ <Company isEn={ isEn } /> } ></Route>
            <Route key="production" path="/production" element={ <Production isEn={ isEn } isAdmin={ isAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setField={ setField } render={ render } setRender={ setRender } /> }></Route>
            <Route key="artist" path="/artist" element={ <Artist isEn={ isEn } isAdmin={ isAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setField={ setField } render={ render } setRender={ setRender } /> }></Route>
            <Route key="musician" path="/musician" element={ <Musician isEn={ isEn } isAdmin={ isAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setField={ setField } render={ render } setRender={ setRender } /> }></Route>
            <Route key="news" path="/news" element={ <News isEn={ isEn } isAdmin={ isAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setField={ setField } render={ render } setRender={ setRender } /> }></Route>
            <Route key="more" path="/news/more" element={ <More isEn={ isEn } isAdmin={ isAdmin } showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setField={ setField } render={ render } setRender={ setRender } /> }></Route>
            <Route key="audition" path="/audition" element={ <Audition isEn={ isEn } /> }></Route>
          </Routes>
          <Footer key="footer" isEn={ isEn } setIsEn={ setIsEn } animated={ animated } setAnimated={ setAnimated }/>
        </AnimatePresence>
      </BrowserRouter>
    </div>
  );
}

export default App;
