import style from "../css/Component.module.css";


const pagePath = {
    "Album/Concert": 'Production',
    "Drama": 'Production',
    "News": "News",
    "Notice": "News",
    "Movie": 'Production',
    "Producer": 'Production'
}

export default function NavigationBox({ page }) {
    
    return(
        <div className={ style.navBox }>
            <h1 className={ style.navBoxPageText }>{ page }</h1>
            <div className={ style.mobileNavBoxPathContainer }>
                {pagePath[page] ? TextWithSeparator(pagePath[page]) : TextWithSeparator('Home')}
                <span className={ style.navBoxPathText }>{ page }</span>
            </div>
            <div className={ style.webNavBoxPathContainer }>
                <>
                    {TextWithSeparator('Home')}
                    {pagePath[page] ? TextWithSeparator(pagePath[page]) : null}
                </>
                {
                    page === "Drama" || page === "Producer" ?
                    TextWithSeparator("Drama")
                    :
                    null
                }
                <span className={ style.navBoxPathText }>{ page }</span>
            </div>
        </div>
    )
}

const TextWithSeparator = text => (
    <>
        <span className={ style.navBoxPathText }>{text}</span>
        <div className={ style.navBoxPathSeparator } />
    </>
)