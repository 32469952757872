import style from "../css/Pages2.module.css";

export default function ArtistItem({ data, isEn, setShowArtist, setArtistIdx }) {

    function showArtist(id) {
        setShowArtist(true)
        setArtistIdx(id)
    }

    return(
        data.map((data, idx) => (
            <div key={ data.id } className={ style.artistItem } style={{ animationDelay: ((idx+1) * 0.05 + 0.6 + parseInt(idx/4) * 0.3) +"s" }} onClick={ () => showArtist(data.id) }>
                <div className={ style.artistItemImg }>
                    <img src={ data.imgUrl } alt="artist" />
                    <div className={ style.artistItemNameContainer }>
                        <div className={ style.artistItemName }>
                            { data.enName }
                            <div className={ style.plusShape }>
                                <div />
                                <div />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}