import { useRef, useState } from "react";
import axios from "axios";
import style from "../css/AdminModal.module.css";
import exit from "../img/component/admin_exit.svg";

export default function AdminMain({ setIsAdmin, setShowAdmin, setRender }) {
    const inputRef = useRef();
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    function closeAdmin() {
        setRender(new Date());
        setShowAdmin(false);
    }

    function onUploadImage(e) {
        if (e.target.value.length === 0) {
            return;
        } else {
            let reader = new FileReader();

            if(e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                setImageFile(e.target.files[0]);
            }

            reader.onloadend = function() {
                setImageSrc(reader.result);
            }
        }
    };

    function previewImage() {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.click();
    }

    function deleteImage() {
        if(imageSrc !== null) {
            if(window.confirm("미리보기 이미지를 삭제하시겠습니까?")) {
                setImageSrc(null)
                setImageFile(null)
                inputRef.current.value = null;
            }
        }
    }

    function uploadImage(e) {
        if(imageFile === null) {
            alert("교체할 이미지를 첨부해주세요.")
            return
        }
        e.preventDefault();
        const formData = new FormData();
        formData.set('file', imageFile);
        axios({
            method: "post",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            url: process.env.REACT_APP_SERVER + "/api/admin/main",
            data: formData,
        })
        .then((Response) => {
            setImageFile(null);
            setImageSrc(null);
            inputRef.current.value = null;
            alert('변경이 완료되었습니다.');
        })
        .catch((Error) => {
            console.log(Error);
        })
    }

    return(
        <div className={ `${style.container} ${style.main}` }>
            <div className={ style.topContainer }>
                <span>메인 이미지 관리</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ style.middleContainer }>
                <div className={ style.themeContainer }>
                    <span className={ style.middleTheme }>이미지 등록/수정</span>
                    <hr className={ style.middleLine }></hr>
                </div>
                <div className={ `${style.contentContainer} ${style.mainContent}` }>
                    <div className={ style.mainImgBox }>
                        {
                            imageSrc && <img src={ imageSrc } alt="previewImage"></img>
                        }
                        <div className={ style.mainPlus }>
                            <hr className={ style.plusShape }></hr>
                            <hr className={ style.plusShape }></hr>
                        </div>
                        <span className={ style.plusMent }>이미지 사이즈는 1920x900px로 업로드 해주세요.</span>
                    </div>
                    <div className={ style.mainButtonBox }>
                        <div className={ `${style.actionButton2} ${style.fncMainBtn}` } onClick={ previewImage }>
                            <span>첨부하기</span>
                        </div>
                        <input type="file" accept="image/*" ref={ inputRef } onChange={ onUploadImage }></input>
                        <div className={ `${style.actionButton2} ${style.fncMainBtn}` } onClick={ deleteImage }>
                            <span>삭제하기</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={ (e) => uploadImage(e) }>
                    <span>등록하기</span>
                </div>
            </div>
        </div>
    )
}