import style from "../css/Pages.module.css";

export default function CompanyItem({item, isEn}) {
    return(
        <div className={ style.companyItem }>
            <div className={ style.companyItemImg }>
                <img src={ item.img } alt={ item.theme }></img>
            </div>
            <div className={ style.companyItemTheme }>
                <span>{ item.theme }</span>
            </div>
            <div className={ style.companyItemContent }>
                <span>{ isEn ? item.enContent : item.content }</span>
            </div>
        </div>
    )
}