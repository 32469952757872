import { Link } from "react-router-dom";
import style from "../css/Pages2.module.css";

export default function NoticeItem({ isEn, data, idx }) {
    
    return(
        <Link to={ window.location.pathname + "/more" + window.location.search + "&postId=" + data.id } className={ style.noticeItem }>
            <div className={ style.noticeItemTop }>
                <div className={ style.noticeItemTheme }>
                    <span>{ isEn ? data.enTheme : data.theme }</span>
                </div>
                <div className={ style.noticeItemContent }>
                    <span>{ isEn ? data.enContent : data.content }</span>
                </div>
            </div>
            <div className={ style.noticeItemBottom }>
                <div className={ style.noticeItemDate }>
                    <span>{ isEn ? data.enDate : data.date }</span>
                </div>
            </div>
        </Link>
    )
}