import style from "../css/AdminModal.module.css";
import exit from "../img/component/admin_exit.svg";
import axios from "axios";
import { useRef } from "react";

export default function AdminLogin({ setIsAdmin, setShowAdmin }) {
    const idRef = useRef();
    const pwRef = useRef();

    function closeAdmin() {
        setShowAdmin(false);
    }

    function login() {
        axios.post(process.env.REACT_APP_SERVER +'/api/admin',
        {
            admin_id: idRef.current.value,
            admin_pw: pwRef.current.value
        })
            .then((Response) => {
                if(Response.data.state === "success") {
                    setIsAdmin(true);
                    setShowAdmin(false);
                    window.localStorage.setItem("login_access", "true")
                } else {
                    alert(Response.data.message);
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
    }

    return(
        <div className={ `${style.container} ${style.login}` }>
            <div className={ style.topContainer }>
                <span>관리자 로그인</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ style.middleContainer }>
                <div className={ style.middleLogin }>
                    <input ref={ idRef } type="text" placeholder="아이디" className={ style.loginTextbox }></input>
                    <input ref={ pwRef } type="password" placeholder="비밀번호" className={ style.loginTextbox }></input>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={ login }>
                    <span>로그인</span>
                </div>
            </div>
        </div>
    )
}