import style from "../css/Pages2.module.css";
import adminStyle from "../css/AdminModal.module.css";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import goBack from "../img/component/go_back.png";
import downloadIcon from "../img/component/download.png";
import NavigationBox from "./NavigationBox";
import Portal from "../Components/Portal";
import { Transition } from "react-transition-group";
import AdminModal from "../PageComponents/AdminModal";

export default function More({ isEn, isAdmin, showAdmin, setShowAdmin, field, setField, render, setRender }) {
    const [getParams] = useSearchParams('');
    const column = getParams.get('column');
    const idx = getParams.get('postId');
    const [getData, setData] = useState([]);
    const [img, setImg] = useState("");
    const navigate = useNavigate();
    const columnK = {
        News: "뉴스",
        Notice: "공지사항",
    }
    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/api/data/' + column.toLowerCase() + '/' + idx)
            .then((Response) => {
                if(Response.data.data[0] === undefined) {
                    alert('존재하지 않는 게시물입니다.')
                    navigate(-1);
                    return
                }
                setData(Response.data.data[0]);
            })
            .catch((Error) => {
                console.log(Error)
            })
    }, [idx, render])

    function deleteData() {
        setField(column.toLowerCase())
        if(window.confirm("정말 삭제하시겠습니까?")) {
            axios({
                method: "delete",
                url: process.env.REACT_APP_SERVER + '/api/admin/delete/' + column.toLowerCase(),
                data: {
                    id: getData.id,
                    column: column.toLowerCase(),
                    imgUrl: getData.imgUrl,
                    file: getData.file
                }
            })
            .then((Response) => {
                if(Response.data.state === "success") {
                    alert("삭제가 완료되었습니다.");
                    navigate(-1);
                    return
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
        }
    }
    
    return(
        <div key={ column } className={ style.wrap }>
            <div className={ style.container }>
                <NavigationBox page={column} />
            </div>
            <div className={ style.moreContainer }>
                <div className={ style.moreThemeContainer }>
                    <span className={ style.moreTheme }>{ isEn ? getData.enTheme : getData.theme }</span>
                    <span className={ style.moreDate }>{ isEn ? getData.enDate : getData.date }</span>
                </div>
                <div className={ style.moreContentContainer }>
                    <span className={ style.moreContent }>{ isEn ? getData.enContent : getData.content }</span>
                    {
                        getData.imgUrl !== null &&
                        <div><img src={ getData.imgUrl } alt="img"></img></div>
                    }
                    <div className={ style.buttonContainer }>
                        <div className={ style.backToList } onClick={ () => navigate(-1) }>
                            <img src={ goBack } alt="뒤로가기"></img>
                            <span>List</span>
                        </div>
                        {
                            column === "Notice" &&
                            <div className={ style.noticeDownload }>
                                {
                                    getData.length !== 0 &&
                                    getData.file.map((file) => (
                                        <a key={ file.id } href={ file.name } download={ file.name.split('/')[file.name.split('/').length - 1] } className={ style.noticeDownloadBtn }>
                                            <img src={downloadIcon} alt={ file.name.split('/')[file.name.split('/').length - 1] }></img>
                                            <div className={ style.spanSplit }>
                                                <span>{ file.name.split('/')[file.name.split('/').length - 1] }</span>
                                                <span>{ "." + file.name.split('/')[file.name.split('/').length - 1].split('.')[1] }</span>
                                            </div>
                                        </a>
                                    ))
                                }
                            </div>
                        }
                    </div>
                    {
                        isAdmin && 
                        <div className={ adminStyle.introMoreContainer }>
                            <div className={ adminStyle.actionButton } onClick={ () => { setShowAdmin(true); setField(column.toLowerCase()) } }>게시물 수정</div>
                            <div className={ adminStyle.actionButton } onClick={ deleteData }>삭제</div>
                        </div>
                    }
                    {
                        (field === "news" || field === "notice") &&
                        <Portal>
                            <Transition unmountOnExit in={ showAdmin } timeout={0}>                      
                                <AdminModal showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } originData={ getData } setRender={ setRender }/>
                            </Transition>
                        </Portal>
                    }
                </div>
            </div>
        </div>
    )
}