import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "../css/Pages2.module.css";

export default function NewsItem({ isEn, data, idx }) {
    const [imgSize, setImgSize] = useState();
    const [img, setImg] = useState("");

    useEffect(() => {
        getImgSize(data.imgUrl)
    })

    function getImgSize(src) {
        var img = new Image();
        if(src !== null && src !== "") {
            img.src = data.imgUrl;
            img.onload = function() {
                setImg(data.imgUrl);
                if(img.width < img.height) {
                    setImgSize(style.big)
                } else {
                    setImgSize(style.small)
                }
            }
        }
    }
    
    return(
        <Link to={ window.location.pathname + "/more" + window.location.search + "&postId=" + data.id } className={ `${ style.newsitem } ${ imgSize }`} style={{ animationDelay: ((idx+1) * 0.05 + 0.6 + parseInt(idx/4) * 0.3) +"s" }}>
            <div className={ style.imgContainer }>
                <img src={ img } alt="뉴스이미지"></img>
            </div>
            <div className={ style.tagContainer }>
                {
                    data.tag.length !== 0 &&
                    isEn ? 
                    data.en_tag.map((tag) => (
                        <span key={ tag.id }>{tag.name}</span>
                    ))
                    :
                    data.tag.map((tag) => (
                        <span key={ tag.id }>{tag.name}</span>
                    ))
                }
            </div>
            <div className={ style.themeContainer }>
                <span>{ isEn ? data.enTheme : data.theme }</span>
            </div>
        </Link>
    )
}