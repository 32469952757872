import style from "../css/Pages.module.css";
import CompanyItem from "../PageComponents/CompanyItem";
import company1 from "../img/component/company1.png";
import company2 from "../img/component/company2.png";
import company3 from "../img/component/company3.png";
import company4 from "../img/component/company4.png";
import company5 from "../img/component/company5.png";
import company6 from "../img/component/company6.png";
import { useEffect } from "react";
import NavigationBox from "../PageComponents/NavigationBox";
import SEOMeta from "../PageComponents/SEOMeta";

export default function Company({ isEn, setSeoData }) {
    const Items = [
        { id: 1, img: company1, theme: "Brave & Born", content: "우리는 용감한 도전으로 새롭게 태어납니다. 도전은 미래를 선도하고 발전의 원동력을 확보합니다.", enContent: "We are reborn through brave challenges. And lead the future and secure the driving force." },
        { id: 2, img: company2, theme: "Best & Believe", content: "우리는 새로운 방향성을 제안하여 최상의 퀄리티를 제공하며, 콘텐츠가 주는 변화를 믿습니다.", enContent: "We propose a new direction, provide the best quality. We believe in the change that content brings." },
        { id: 3, img: company3, theme: "Broad & Build", content: "우리는 폭넓은 재미와 즐거움뿐만 아니라 감동 그 이상을 전달하는 콘텐츠를 만듭니다.", enContent: "We create content that delivers not only a wide range of fun and enjoyment, but also excitement." },
        { id: 4, img: company4, theme: "Contents Producing", content: "콘텐츠의 기획 및 편집 등 제작 업무를 진행합니다.", enContent: "Proceed with production tasks such as content planning and editing." },
        { id: 5, img: company5, theme: "Marketing Team", content: "콘텐츠의 마케팅 기획과 실행, 프로모션 담당 등 업무를 진행합니다.", enContent: "Responsible for marketing planning, execution, and promotion." },
        { id: 6, img: company6, theme: "Flow Management", content: "콘텐츠의 마케팅 기획과 실행, 프로모션 담당 등 업무를 진행합니다.", enContent: "We are in charge of content copyright and distribution management." },
    ];

    return(
        <div className={ style.wrap }>
            <SEOMeta title="Company ㅣ 빌리언스" description="빌리언스는 드라마 제작, 매니지먼트 등 다양한 콘텐츠를 기획, 개발, 전달하며 창의적인 생각으로 전 세계 소비자에게 사랑받는 '토털 엔터테인먼트 그룹'으로 나아갈 것입니다." url="https://bladeent.com/company"></SEOMeta>
            <div className={ style.container }>
                <NavigationBox page="Company" />
                <div className={ style.commentContainer }>
                    <div className={ style.commentTheme }>
                        <span>Our Belief Is Brave</span>
                    </div>
                    <div className={ style.commentContent }>
                        <span>{ isEn ? "BILLIONS plans, develops, and delivers various contents such as drama production and management, and will move forward as a ‘total entertainment group’ loved by consumers around the world." : "BILLIONS는 드라마 제작, 매니지먼트 등 다양한 콘텐츠를 기획, 개발, 전달하며 창의적인 생각으로 전 세계 소비자에게 사랑받는 ‘토털 엔터테인먼트 그룹’으로 나아갈 것입니다." }</span>
                    </div>
                </div>
                <div className={ style.companyContainer }>
                    {
                        Items.map((item) => (
                            <CompanyItem key={ item.id } item={ item } isEn={ isEn } />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}