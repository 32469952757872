import style from "../css/Pages.module.css";
import downloadIcon from "../img/component/download.svg";
import file1 from "../file/블레이드엔터테인먼트 이메일 오디션_개인정보동의서.docx"
import file2 from "../file/블레이드엔터테인먼트 이메일 오디션_지원서.docx"
import NavigationBox from "../PageComponents/NavigationBox";
import { useEffect } from "react";
import SEOMeta from "../PageComponents/SEOMeta";

export default function Audition({ isEn, setSeoData }) {
    return(
        <div className={ style.wrap }>
            <SEOMeta title="Audition ㅣ 빌리언스" description="빌리언스의 내일에 함께할 당신을 기다리고 있습니다." url="https://bladeent.com/audition"></SEOMeta>
            <div className={ style.container }>
                <NavigationBox page="Audition" />
                <div className={ style.auditionForm }>
                    <div className={ style.auditionItem }>
                        <div className={ style.auditionItemDiv }>
                            <div className={ style.auditionTheme }>
                                <span>{isEn ? "Field" : "모집 분야"}</span>
                            </div>
                            <div className={ style.auditionContent }>
                                <span>{isEn ? "Actor / Singer" : "연기자 가수"}</span>
                            </div>
                        </div>
                        <div className={ style.auditionItemDiv }>
                            <div className={ style.auditionTheme }>
                                <span>{isEn ? "Way" : "모집 방법"}</span>
                            </div>
                            <div className={ style.auditionContent }>
                                <span>{isEn ? "Occasional recruitment" : "수시 모집"}</span>
                            </div>
                        </div>
                    </div>
                    <div className={ style.auditionItem }>
                        <div className={ style.auditionTheme }>
                            <span>{isEn ? "Type" : "전형안내"}</span>
                        </div>
                        <div className={ style.auditionContent }>
                            <div className={ style.auditionContentSpace }>
                                <span>1.</span>
                                <span>{ isEn ? "email reception" : "이메일 접수" }</span>
                            </div>
                            <div className={ style.auditionContentSpace }>
                                <span>2.</span>
                                {
                                    isEn ?
                                    <span>Notification of individual acceptance <font className={ style.auditionContentEx }>(Only successful applicants)</font></span>
                                    :
                                    <span>개별 합격 통보 <font className={ style.auditionContentEx }>(합격자에 한해 개별 연락드립니다.)</font></span>
                                }
                            </div>
                            <div className={ style.auditionContentSpace }>
                                <span>3.</span>
                                <span>{ isEn ? "closed audition" : "비공개 오디션" }</span>
                            </div>
                            <div className={ style.auditionContentSpace }>
                                <span>4.</span>
                                <span>{ isEn ? "final pass" : "최종합격" }</span>
                            </div>
                        </div>
                    </div>
                    <div className={ style.auditionItem }>
                        <div className={ style.auditionTheme }>
                            <span>{isEn ? "Email reception" : "이메일 접수"}</span>
                        </div>
                        <div className={ style.auditionContent }>
                            <div className={ style.auditionContentFlex }>
                                <div className={ style.flexTheme }>
                                    <span>{ isEn ? "Common filing documents" : "공통 제출 서류"}</span>
                                </div>
                                <div className={ style.flexContent }>
                                    <span>{ isEn ? "- application" : "- 지원서"}</span>
                                    <span>{ isEn ? "- At least 3 profile photos (face close-up and full body photos required, no calibration)" : "- 프로필 사진 3장 이상(얼굴 클로즈업과 전신사진 필수, 과한 보정 금지)"}</span>
                                    <span>{ isEn ? "- Personal Information Agreement " : "- 개인정보제공동의서 "}</span>
                                    <span>{ isEn ? "- Email subject : [position] name / sex / year of birth" : "- 이메일 제목 : [지원분야] 이름 / 성별 / 출생년도"}</span>
                                </div>
                            </div>
                            <div className={ style.auditionContentFlex }>
                                <div className={ style.flexTheme }>
                                    <span>{ isEn ? "Actor" : "연기자"}</span>
                                </div>
                                <div className={ style.flexContent2 }>
                                    <span>{ isEn ? "- More than 60 seconds of free acting video files" : "- 60초 이상의 자유 연기 영상 파일"}</span>
                                </div>
                            </div>
                            <div className={ style.auditionContentFlex }>
                                <div className={ style.flexTheme }>
                                    <span>{ isEn ? "Singer" : "가수"}</span>
                                </div>
                                <div className={ style.flexContent2 }>
                                    <span>{ isEn ? "- More than 60 seconds of free acting video files" : "- 60초 이상의 자유 노래 영상 파일"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={ style.auditionItem }>
                        <div className={ style.auditionTheme }>
                            <span>{isEn ? "Submit" : "지원 방법"}</span>
                        </div>
                        <div className={ style.auditionContent }>
                            <div className={ style.auditionContentFlex }>
                                <div className={ style.flexTheme }>
                                    <span>bladeent@bladeent.com</span>
                                </div>
                                <div className={ style.flexContent }>
                                    <span>{ isEn ? "- Please submit after checking the submission file by email above." : "- 상단의 이메일로 제출 서류 파일을 확인 후 접수해주세요. "}</span>
                                </div>
                                <div className={ style.flexButton }>
                                    <a className={ style.downloadButton } href={ file2 } download="블레이드엔터테인먼트 이메일 오디션_지원서.docx">
                                        <img src={ downloadIcon } alt="download"></img>
                                        <span>{ isEn ? "download application form" : "지원서 다운로드" }</span>
                                    </a>
                                    <a className={ style.downloadButton } href={ file1 } download="블레이드엔터테인먼트 이메일 오디션_개인정보동의서.docx">
                                        <img src={ downloadIcon } alt="download"></img>
                                        <span>{ isEn ? "Privacy Agreement" : "개인정보 동의서" }</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}