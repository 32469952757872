import style from "../css/Pages2.module.css";

export default function MusicianItem({ data, isEn, setShowMusician, setMusicianIdx }) {

    function showMusician(id) {
        setShowMusician(true)
        setMusicianIdx(id)
    }

    return(
        data.map((data, idx) => (
            <div key={ data.id } className={ style.musicianItem } style={{ animationDelay: ((idx+1) * 0.05 + 0.6 + parseInt(idx/4) * 0.3) +"s" }} onClick={ () => showMusician(data.id) }>
                <div className={ style.musicianItemImg }>
                    <img src={ data.imgUrl } alt="musician" />
                    <div className={ style.artistItemNameContainer }>
                        <div className={ style.artistItemName }>
                            { data.enName }
                            <div className={ style.plusShape }>
                                <div />
                                <div />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}